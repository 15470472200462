import { onError } from "@apollo/client/link/error";
import { toast } from "vue-sonner";
import { graphqlErrorMap } from "@/lib/data-maps";

export const errorLink = onError(({ graphQLErrors, forward, operation, networkError }) => {
	for (const error of graphQLErrors ?? []) {
		const suppressUnauthMessage =
			error.path?.includes("myPermissionsHaveChanged") ||
			error.path?.includes("hasAcceptedPrivacyStatement") ||
			error.path?.includes("me");

		if (!suppressUnauthMessage) {
			const name = (error?.extensions?.exception as { name?: string })?.name;

			if (!name) {
				return;
			}
			const errorMessage = graphqlErrorMap[name];

			toast.error(errorMessage, { id: errorMessage });
			console.error(errorMessage);
		}
	}

	if (networkError) {
		console.error("[Network error] ", networkError);
	}

	return forward(operation);
});
