<template>
	<VDropdown :offset="[0, 8]" :triggers="[]" :auto-hide="false" :shown="showPopover">
		<div class="sb_elevator" @click="showPopover = true">
			<input
				:id="id"
				:value="modelValue"
				type="text"
				v-bind="$attrs"
				class="sb_elevator_element"
				@input="handle"
				@click="showPopover = true"
				@focus="showPopover = true"
			/>

			<sb-icon class="sb_elevator_icon" name="elevator" />
		</div>

		<template #popper>
			<div ref="popper" class="sb_elevator_popover">
				<button
					v-for="option in options"
					:key="option"
					class="sb_elevator_popover_button"
					@click="() => changeValue(option)"
				>
					{{ option }}
				</button>
			</div>
		</template>
	</VDropdown>
</template>

<script setup lang="ts">
import { ref, toRefs } from "vue";
import { onClickOutside } from "@vueuse/core";
import SbIcon from "@/components/atoms/Icon.vue";

const props = withDefaults(defineProps<{ id?: string; modelValue: string; options: string[] }>(), {
	id: `sb_elevator_${crypto.randomUUID()}`,
});

const { modelValue } = toRefs(props);

const emits = defineEmits<{ (e: "update:modelValue", value: string): void }>();

const popper = ref();
const value = ref<string>(modelValue.value);
const showPopover = ref<boolean>(false);

onClickOutside(popper, () => (showPopover.value = false));

const handle = (event: Event) => {
	if (event.target) {
		const element = event.target as HTMLInputElement;

		changeValue(element.value);
	}
};

const changeValue = (to: string) => {
	showPopover.value = false;
	value.value = to;
	emits("update:modelValue", to);
};
</script>

<style lang="scss">
.sb_elevator {
	--elevator-size: 32px;
	--placeholder-color: #{$brand-gray};
	--color: #{$text-color};
	--border-color: #{$border-color};

	position: relative;
	display: flex;
	gap: $padding * 0.5;
	align-items: center;
	height: var(--elevator-size);
	cursor: pointer;

	&_element {
		font-size: $font-size;
		display: block;
		height: 100%;
		appearance: none;
		border-width: $border-width * 2;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		border-top-width: 0;
		border-style: $border-style;
		border-color: var(--border-color);
		margin-block-start: $border-width * 2;
		padding: $padding * 0.5;
		color: var(--color);
		outline: none;

		&::placeholder {
			color: var(--placeholder-color);
		}
	}

	&_icon {
		position: absolute;
		top: 0;
		right: $padding * 0.5;
		bottom: 0;
		pointer-events: none;
		height: 100%;
	}
}
</style>

<!-- Global styles for the content inside the popover -->
<style lang="scss">
.sb_elevator {
	&_popover {
		margin: -1.5em;
		min-width: 20ch;
		display: flex;
		flex-direction: column;

		&_button {
			cursor: pointer;
			padding: 0.5em 1em;
			color: $brand-primary;
			border-bottom-color: rgba($brand-primary-lighter, 0.5);
			border-bottom-width: $border-width;
			border-bottom-style: $border-style;

			&:first-of-type {
				border-top-left-radius: 0.2rem;
				border-top-right-radius: 0.2rem;
			}

			&:last-of-type {
				border-bottom-left-radius: 0.2rem;
				border-bottom-right-radius: 0.2rem;
				border-bottom: none;
			}

			&:hover,
			&:active {
				background: rgba($brand-primary-lighter, 0.1);
			}
		}
	}
}
</style>
