import { clientEnv } from "@/lib/env/client.mjs";

export const msalOptions = {
	clientId: clientEnv.VITE_MSAL_CLIENT_ID,
	domain: `${clientEnv.VITE_MSAL_DOMAIN}/`,
	loginAuthority: clientEnv.VITE_MSAL_LOGIN_AUTHORITY,
	registerAuthority: clientEnv.VITE_MSAL_REGISTER_AUTHORITY,
	passwordResetAuthority: clientEnv.VITE_MSAL_PASSWORD_RESET_AUTHORITY,
	knownAuthority: clientEnv.VITE_MSAL_KNOWN_AUTHORITY,
};
