<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
	TooltipContent,
	type TooltipContentEmits,
	type TooltipContentProps,
	TooltipPortal,
	useForwardPropsEmits,
} from "radix-vue";
import { cx } from "@/lib/style/cva.config";

defineOptions({
	inheritAttrs: false,
});

const props = withDefaults(
	defineProps<TooltipContentProps & { class?: HTMLAttributes["class"] }>(),
	{
		sideOffset: 4,
		class: undefined,
	},
);

const emits = defineEmits<TooltipContentEmits>();

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<TooltipPortal>
		<TooltipContent
			v-bind="{ ...forwarded, ...$attrs }"
			:class="
				cx(
					'z-50 overflow-hidden rounded-md border border-neutral-200 bg-white px-3 py-1.5 text-sm text-neutral-950 shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-neutral-800 dark:bg-neutral-950 dark:text-neutral-50',
					props.class,
				)
			"
		>
			<slot />
		</TooltipContent>
	</TooltipPortal>
</template>
