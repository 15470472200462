<template>
	<teleport to="body">
		<transition name="privacy-statement" appear>
			<div v-if="shouldShow" class="sb_privacy-statement-alert" v-bind="$attrs">
				<div class="sb_privacy-statement-alert_inner">
					<div class="sb_privacy-statement-alert_backdrop" @click="confirmSignOut"></div>
					<div class="sb_privacy-statement-alert_content">
						<sb-text type="h3">Privacy statement</sb-text>

						<sb-text type="p"
							>Since the last time you've visited the site, the privacy statement has been updated.
							You need to accept this updated privacy statement to continue.
						</sb-text>

						<sb-text type="p" class="version"
							>Updated privacy statement (version {{ version }} published at {{ date }}):</sb-text
						>

						<!-- eslint-disable-next-line vue/no-v-html,vue/no-v-text-v-html-on-component -->
						<sb-text type="p" v-html="content" />

						<div class="sb_privacy-statement-alert_content_actions">
							<sb-button button-style="outline" @click="signOut"> Deny and log out </sb-button>
							<sb-button @click="privacyStatementStore.accept"> Accept </sb-button>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</teleport>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { useIsAuthenticated } from "@stijlbreuk/msal-vue";
import { storeToRefs } from "pinia";
import { graphql } from "@/graphql";
import type { ServiceHandle } from "@/graphql/graphql";
import { signOut } from "@/store/auth";
import { useDialogStore } from "@/store/dialog";
import { usePrivacyStatementStore } from "@/store/privacy-statement";

const isAuthenticated = useIsAuthenticated();
const { confirmDialog } = useDialogStore();
const privacyStatementStore = usePrivacyStatementStore();
const { hasAccepted } = storeToRefs(privacyStatementStore);

const shouldShow = computed(() => isAuthenticated.value && !hasAccepted.value);

const query = useQuery(
	graphql(`
		query PrivacyStatementAlert($service: String!) {
			activePrivacyStatement(service: $service) {
				id
				publishedAt
				version
				content
			}
		}
	`),
	{ service: "CNMIM" satisfies ServiceHandle },
	{ enabled: shouldShow },
);

const privacyStatement = computed(() => query.result.value?.activePrivacyStatement);

const content = computed(
	() =>
		privacyStatement.value?.content ??
		`By using CNMIM, you agree that Corbion employees may reach out to you via phone or email to see if you require any further information. If you wish to know more about how Corbion processes your personal data, please refer to our <a href="http://www.corbion.com/about-corbion/privacy">Privacy Statement</a>. If you no longer wish to be contacted by us, please send an e-mail to <a href="mailto:privacy@corbion.com">privacy@corbion.com</a> or reach out to your respective Corbion contact.`,
);
const version = computed(() => privacyStatement.value?.version ?? 1);
const date = computed(() =>
	new Date(privacyStatement.value?.publishedAt || new Date(2022, 1, 10)).toLocaleString("default", {
		dateStyle: "full",
		timeStyle: "medium",
	}),
);

async function confirmSignOut() {
	if (
		await confirmDialog({
			message: "Are you sure you want to deny the terms of use and log out?",
			cancelText: "Cancel",
			confirmText: "Deny and log out",
		})
	) {
		signOut();
	}
}
</script>

<style lang="scss">
.sb_privacy-statement-alert {
	--padding: #{$padding};

	position: fixed;
	inset: 0;
	isolation: isolate;
	display: grid;
	place-items: center;
	z-index: 30000;
	padding: $padding;

	&_backdrop {
		position: fixed;
		inset: 0;
		background: rgba($brand-primary, 0.75);
		backdrop-filter: blur(3px);
	}

	&_content {
		padding: $padding;
		border-radius: $border-radius;
		background: $brand-white;
		display: flex;
		flex-direction: column;
		gap: $padding * 0.5;
		max-width: min(40ch, calc(100vw - calc(#{$padding} * 2)));
		margin-inline: auto;
		max-height: calc(100vh - calc(#{$padding} * 2));
		overflow-y: auto;
		width: 100vw;
		will-change: transform;

		@include media(">=md") {
			min-width: 60ch;
			width: 75vw;
		}

		&_actions {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			gap: $padding;
		}

		.version {
			font-weight: bold;
		}
	}
}

.privacy-statement-enter-active,
.privacy-statement-leave-active {
	transition-property: opacity;
	transition-timing-function: $transition-timing-function;
	transition-duration: $transition-duration-short;

	.sb_privacy-statement-alert_content {
		transition-property: transform;
		transition-timing-function: $transition-timing-function;
		transition-duration: $transition-duration-short;
	}
}

.privacy-statement-enter-from,
.privacy-statement-leave-to {
	opacity: 0;

	.sb_privacy-statement-alert_content {
		transform: scale(0.9) translateY(-10%) rotateX(45deg);
	}
}
</style>
