import { decodeJwt } from "jose";
import { usePermissionsStore } from "@/store/permissions";
import type { PermissionValue } from "@/types/permissions";

export enum PERMISSIONS {
	PRODUCTS_READ__PROTOTYPE = "cnmim.products.read:prototype",
	PRODUCTS_READ__PURE = "cnmim.products.read:pure",
	PRODUCTS_READ__CORBION = "cnmim.products.read:corbion",
	PRODUCTS_READ__ANTIMICROBIALS = "cnmim.products.read:antimicrobials",
	FORMULATION_SOLVE = "cnmim.formulation.solve",
	FORMULATION_MOLDRESULTS = "cnmim.formulation.mold_results",
	FORMULATION_DETAILEDRESULTS = "cnmim.formulation.detailed_results",
	FORMULATION_PRINTPDF = "cnmim.formulation.print_pdf",
	FORMULATION_EXPORTEXCEL = "cnmim.formulation.export_excel",
	FORMULATION_COMPACTMODE = "cnmim.formulation.compact_mode",
	FORMULATION_CURRENTDAYSTOMOLD = "cnmim.formulation.current_days_to_mold",
	SODIUM_CALCULATE = "sodium.calculate",
}

export const permissions = {
	[PERMISSIONS.PRODUCTS_READ__PURE]: false,
	[PERMISSIONS.PRODUCTS_READ__CORBION]: false,
	[PERMISSIONS.PRODUCTS_READ__ANTIMICROBIALS]: false,
	[PERMISSIONS.PRODUCTS_READ__PROTOTYPE]: false,
	[PERMISSIONS.FORMULATION_SOLVE]: 0,
	[PERMISSIONS.FORMULATION_MOLDRESULTS]: false,
	[PERMISSIONS.FORMULATION_DETAILEDRESULTS]: false,
	[PERMISSIONS.FORMULATION_PRINTPDF]: false,
	[PERMISSIONS.FORMULATION_EXPORTEXCEL]: false,
	[PERMISSIONS.FORMULATION_COMPACTMODE]: false,
	[PERMISSIONS.FORMULATION_CURRENTDAYSTOMOLD]: false,
	[PERMISSIONS.SODIUM_CALCULATE]: false,
};

export function getPermissionValue(permissionHandle: PERMISSIONS) {
	const permission = usePermissionsStore().permissions.find(
		({ handle }) => handle === permissionHandle,
	);

	if (permission) {
		return permission.value as number | boolean;
	}

	// Default
	return permissions[permissionHandle];
}

export function getPermissions(accessToken: string) {
	if (accessToken.length < 10) {
		// FIX LATER.
		return [];
	}

	const decoded = decodeJwt(accessToken);

	if (!decoded) {
		return [];
	}

	const permissions = JSON.parse((decoded as any).extension_Permissions) as PermissionValue[];

	return permissions;
}
