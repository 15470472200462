<template>
	<input
		:id="id"
		type="radio"
		class="sb_radio"
		:checked="checked"
		:data-checked="checked"
		v-bind="$attrs"
		@input="handle"
	/>
</template>

<script setup lang="ts">
import { ref, toRefs, watch } from "vue";

const props = withDefaults(defineProps<{ id?: string; modelValue: boolean }>(), {
	id: `sb_radio_${crypto.randomUUID()}`,
});

const { modelValue } = toRefs(props);

const emits = defineEmits<{ (e: "update:modelValue", value: boolean): void }>();

const checked = ref<boolean>(modelValue.value);

watch(modelValue, (newValue) => {
	checked.value = newValue;
});

const handle = (event: Event) => {
	if (event.target) {
		const element = event.target as HTMLInputElement;

		checked.value = element.checked;
		emits("update:modelValue", element.checked);
	}
};
</script>

<style lang="scss">
.sb_radio {
	--radio-size: 24px;
	--transition-duration: #{$transition-duration-short};
	--transition-timing-function: #{$transition-timing-function};
	--active-color: #{$brand-primary-lighter};
	--border-width: #{$border-width * 2};

	position: relative;
	appearance: none;
	cursor: pointer;
	width: var(--radio-size);
	height: var(--radio-size);
	border-radius: 100%;

	&::before {
		content: "";
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		border-radius: 100%;
		transition-property: border-width;
		transition-duration: var(--transition-duration);
		transition-timing-function: var(--transition-timing-function);
		display: flex;
		justify-content: center;
		align-items: center;
		background: $brand-white;
		border-width: var(--border-width);
		border-style: $border-style;
		border-color: var(--active-color);
	}

	&:hover,
	&:focus {
		&::before {
			border-width: calc(var(--radio-size) * 0.15);
		}
	}

	&:active {
		&::before {
			border-width: calc(var(--radio-size) * 0.2);
		}
	}

	&[data-checked="true"] {
		&::before {
			border-width: calc(var(--radio-size) * 0.3);
		}
	}
}
</style>
