import { z } from "zod";

export const FoodTypeSchema = z.enum(["bread-buns", "sweet-goods", "tortilla"]);
export type FoodType = z.infer<typeof FoodTypeSchema>;

export const FOOD_TYPE = {
	"bread-buns": { label: "Bread & buns", disabled: false },
	tortilla: { label: "Tortilla", disabled: true },
	"sweet-goods": { label: "Sweet goods", disabled: true },
} as const satisfies Record<FoodType, { label: string; disabled: boolean }>;
