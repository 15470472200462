<script lang="ts">
import type { HTMLAttributes, InjectionKey } from "vue";
import { useFormField } from "@/components/ui/form/useFormField";

export const FORM_ITEM_INJECTION_KEY = Symbol() as InjectionKey<string>;
</script>

<script lang="ts" setup>
import { provide } from "vue";
import { useId } from "radix-vue";
import { cx } from "@/lib/style/cva.config";

const props = defineProps<{
	class?: HTMLAttributes["class"];
}>();

const id = useId();
provide(FORM_ITEM_INJECTION_KEY, id);

const { error } = useFormField();
</script>

<template>
	<div :class="cx('space-y-2', error && '*:border-red-500 dark:*:border-red-900', props.class)">
		<slot />
	</div>
</template>
