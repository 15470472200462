<template>
	<VDropdown :shown="showError" theme="input-error">
		<div class="sb_multiselect">
			<Multiselect
				:id="id"
				v-model="value"
				class="sb_multiselect_element"
				:options="options"
				v-bind="$attrs"
				:data-error="showError"
				:data-error-message="errorMessage"
				:placeholder="placeholder"
				@select="emits('select', $event)"
			/>
		</div>

		<template #popper>
			<sb-text v-if="errorMessage" type="p">{{ errorMessage }}</sb-text>
		</template>
	</VDropdown>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch } from "vue";
import { default as Multiselect } from "@vueform/multiselect";
import SbText from "@/components/atoms/Text.vue";

const props = withDefaults(
	defineProps<{
		id?: string;
		error?: boolean;
		errorMessage?: string;
		modelValue: string | number;
		options: Array<
			| { value: string | number; label: string }
			| {
					label: string;
					options: Array<{ value: string | number; label: string }>;
			  }
		>;
		placeholder?: string;
	}>(),
	{
		id: `sb_multiselect_${crypto.randomUUID()}`,
		error: false,
		errorMessage: undefined,
		placeholder: undefined,
	},
);

const { modelValue, error, errorMessage } = toRefs(props);

const emits = defineEmits<{
	(e: "update:model-value", value: string | number): void;
	(e: "select", value: string | number): void;
}>();

const supressError = ref<boolean>(false);

const value = ref<string | number>(modelValue.value);
watch(modelValue, (newValue) => {
	value.value = newValue;
});
watch(value, (newValue) => {
	emits("update:model-value", newValue);
});

watch(errorMessage, () => {
	supressError.value = false;
});

const showError = computed(
	() => (error.value || !!errorMessage.value) && !!errorMessage.value && !supressError.value,
);
</script>

<style lang="scss">
@import "~@vueform/multiselect/themes/default.scss";

.sb_multiselect {
	--multiselect-size: 32px;

	height: var(--multiselect-size);

	&_element {
		--ms-bg: transparent;
		--ms-bg-disabled: #{$brand-lighter-gray};
		--ms-border-color: #{$border-color};
		--ms-border-width: #{$border-width * 2};
		--ms-radius: #{$border-radius};
		--ms-py: #{$padding * 0.5};
		--ms-px: #{$padding * 0.5};
		--ms-ring-width: 0;
		--ms-ring-color: transparent;
		--ms-placeholder-color: #{$brand-gray};
		--ms-max-height: #{$padding * 15};
		--ms-spinner-color: #{$brand-primary-lighter};
		--ms-caret-color: #{$border-color};
		--ms-clear-color: #{$border-color};
		--ms-clear-color-hover: #{$brand-primary};
		--ms-dropdown-bg: #{$brand-white};
		--ms-dropdown-border-color: #{$border-color};
		--ms-dropdown-border-width: #{$border-width * 2};
		// Below makes text blurry
		// --ms-dropdown-radius: #{$border-radius};
		--ms-dropdown-radius: 0;
		--ms-option-bg-pointed: #{$brand-lighter-gray};
		--ms-option-bg-selected: #{$brand-primary-lighter};
		--ms-option-bg-disabled: #{$brand-gray};
		--ms-option-bg-selected-pointed: #{$brand-primary-lighter};
		--ms-option-bg-selected-disabled: #{$brand-gray};
		--ms-empty-color: #{$text-color};

		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
		border-top: none !important;
		margin-block-start: $border-width;
		min-height: var(--multiselect-size);
		height: var(--multiselect-size);
		width: 100%;
		min-width: 150px;
		color: $text-color;

		> .multiselect-search {
			padding-right: 38px;
		}

		> .multiselect-single-label {
			display: inline-block;
			height: min-content;
			top: 50%;
			transform: translateY(-50%);
			width: calc(100% - 6px);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		> .multiselect-dropdown {
			> .multiselect-options {
				> .multiselect-option {
					> span {
						width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}
}
</style>
