<template>
	<Dialog v-model:open="dialogOpen">
		<DialogTrigger as-child>
			<Button variant="link" size="auto" v-bind="$attrs"> Share this tool </Button>
		</DialogTrigger>
		<DialogContent class="max-h-[90dvh] overflow-y-auto">
			<DialogHeader>
				<DialogTitle class="text-2xl">Share this tool</DialogTitle>
				<DialogDescription>
					Want to share this tool with others? Fill out the form below to send them an e-mail.
				</DialogDescription>
			</DialogHeader>

			<form class="flex flex-col gap-6" @submit="onSubmit">
				<section class="flex flex-col gap-2 rounded bg-brand-primary-lighter/10 p-4 text-sm">
					<div>
						<Text as="p">
							Dear
							<span :class="cx(!form.values.recipient?.name && 'italic opacity-75')">
								{{ form.values.recipient?.name || "[recipient]" }} </span
							>,
						</Text>
						<Text as="p">
							<span :class="cx(!form.values.sender?.name && 'italic opacity-75')">
								{{ form.values.sender?.name || "[sender]" }}
							</span>
							thought you might be interested in the CNMIM.
						</Text>
					</div>

					<div v-if="!!form.values.message">
						<Text as="p">
							<span :class="cx(!form.values.sender?.name && 'italic opacity-75')">
								{{ form.values.sender?.name || "[sender]" }}
							</span>
							added the following message:
						</Text>
						<Text as="p">{{ form.values.message }}</Text>
					</div>

					<div>
						<Text as="p">
							For more information, please visit
							<a href="https://cnmim.corbion.com" target="_blank" class="hover:underline"
								>cnmim.corbion.com</a
							>.
						</Text>
					</div>

					<div>
						<Text as="p">Thank you,</Text>
						<Text as="p" :class="cx(!form.values.sender?.name && 'italic opacity-75')">
							{{ form.values.sender?.name || "[sender]" }}
						</Text>
					</div>
				</section>

				<div class="flex flex-col gap-1">
					<Text as="h4" variant="heading" class="text-xl font-semibold tracking-tight">Sender</Text>

					<FormField v-slot="{ componentField }" name="sender.name">
						<FormItem>
							<FormLabel>Name</FormLabel>
							<FormControl>
								<Input
									type="text"
									placeholder="John Doe"
									autocomplete="name"
									v-bind="componentField"
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					</FormField>
					<FormField v-slot="{ componentField }" name="sender.email">
						<FormItem>
							<FormLabel>Email</FormLabel>
							<FormControl>
								<Input
									type="email"
									placeholder="john@example.com"
									autocomplete="email"
									v-bind="componentField"
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					</FormField>
				</div>

				<div class="flex flex-col gap-1">
					<Text as="h4" variant="heading" class="text-xl font-semibold tracking-tight">
						Recipient
					</Text>

					<FormField v-slot="{ componentField }" name="recipient.name">
						<FormItem>
							<FormLabel>Name</FormLabel>
							<FormControl>
								<Input type="text" placeholder="Jane Doe" v-bind="componentField" />
							</FormControl>
							<FormMessage />
						</FormItem>
					</FormField>
					<FormField v-slot="{ componentField }" name="recipient.email">
						<FormItem>
							<FormLabel>Email</FormLabel>
							<FormControl>
								<Input type="email" placeholder="jane@example.com" v-bind="componentField" />
							</FormControl>
							<FormMessage />
						</FormItem>
					</FormField>
				</div>

				<FormField v-slot="{ componentField }" name="message">
					<FormItem>
						<FormLabel>Message</FormLabel>
						<FormControl>
							<Textarea
								placeholder="Hey! I thought you might want to check this out!"
								class="resize-none"
								v-bind="componentField"
							/>
						</FormControl>
						<FormMessage />
					</FormItem>
				</FormField>

				<div class="flex justify-end">
					<Button
						type="submit"
						class="gap-2 bg-brand-primary-lighter hover:bg-brand-primary-lighter/90"
					>
						Send <Send class="size-4" />
					</Button>
				</div>
			</form>
		</DialogContent>
	</Dialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { toTypedSchema } from "@vee-validate/zod";
import { Send } from "lucide-vue-next";
import { useForm } from "vee-validate";
import { toast } from "vue-sonner";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import Text from "@/components/ui/custom/Text.vue";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { AnalyticsAction, logActivity } from "@/lib/logActivity";
import { cx } from "@/lib/style/cva.config";

const props = withDefaults(
	defineProps<{
		/** The controlled open state of the dialog. Can be binded as `v-model:open`. */ open?: boolean;
	}>(),
	{
		open: undefined,
	},
);
const emits = defineEmits<{ "update:open": [value: boolean] }>();

const internalOpen = ref<boolean>(props.open ?? false);
const dialogOpen = computed({
	get: () => props.open ?? internalOpen.value,
	set: (newValue) => {
		emits("update:open", newValue ?? false);
		internalOpen.value = newValue;
	},
});

const userSchema = z.object({
	name: z.string(),
	email: z.string().email(),
});

const formSchema = z.object({
	sender: userSchema,
	recipient: userSchema,
	message: z.string().optional(),
});

const form = useForm({ validationSchema: toTypedSchema(formSchema) });

const onSubmit = form.handleSubmit(async (values) => {
	logActivity(AnalyticsAction.ToolShared);

	const toastId = toast.loading("Sending email...");

	window.open(
		`mailto:${values.recipient.email}?subject=${
			values.sender.name
		} thought you might be interested in the CNMIM&body=Dear ${values.recipient.name},%0D%0A%0D%0A${
			values.sender.name
		} thought you might be interested in the CNMIM.%0D%0AFor more information, please visit https://cnmim.corbion.com.%0D%0A%0D%0A${
			values.message
				? `${values.sender.name} added the following message:%0D%0A${values.message}`
				: ``
		}%0D%0A%0D%0AThank you,%0D%0A${values.sender.name}`,
		"_blank",
	);

	toast.success(`Created an email for ${values.recipient.name}`, { id: toastId });

	dialogOpen.value = false;
}, console.error);
</script>
