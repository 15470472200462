import type { App } from "vue";
import { reactive } from "vue";
import type { AccountInfo, Logger, PublicClientApplication } from "@azure/msal-browser";
import {
	type EventMessage,
	EventMessageUtils,
	EventType,
	InteractionStatus,
} from "@azure/msal-browser";
import { name as SKU, version } from "./packageMetadata";
import { accountArraysAreEqual } from "./utils/utilities";

export const msalVuePlugin = {
	install: (app: App, instance: PublicClientApplication) => {
		instance.initialize().then(() => {
			const state = reactive<{
				instance: PublicClientApplication;
				inProgress: InteractionStatus;
				accounts: AccountInfo[];
				logger: Logger;
			}>({
				instance: instance,
				inProgress: InteractionStatus.Startup,
				accounts: instance.getAllAccounts(),
				logger: instance.getLogger().clone(SKU, version),
			});

			app.config.globalProperties.$msal = state;

			instance.addEventCallback((message: EventMessage) => {
				switch (message.eventType) {
					case EventType.ACCOUNT_ADDED:
					case EventType.ACCOUNT_REMOVED:
					case EventType.LOGIN_SUCCESS:
					case EventType.SSO_SILENT_SUCCESS:
					case EventType.HANDLE_REDIRECT_END:
					case EventType.LOGIN_FAILURE:
					case EventType.SSO_SILENT_FAILURE:
					case EventType.LOGOUT_END:
					case EventType.ACQUIRE_TOKEN_SUCCESS:
					case EventType.ACQUIRE_TOKEN_FAILURE:
						// eslint-disable-next-line no-case-declarations
						const currentAccounts = instance.getAllAccounts();
						if (!accountArraysAreEqual(currentAccounts, state.accounts)) {
							state.logger.info("MsalProvider - updating account state");
							state.accounts = currentAccounts;
						} else {
							state.logger.info("MsalProvider - no account changes");
						}
						break;
				}

				const status = EventMessageUtils.getInteractionStatusFromEvent(message, state.inProgress);
				if (status !== null) {
					state.logger.info(
						`MsalProvider - ${message.eventType} results in setting inProgress from ${state.inProgress} to ${status}`,
					);
					state.inProgress = status;
				}
			});

			instance
				.handleRedirectPromise()
				.catch(() => {
					// Errors should be handled by listening to the LOGIN_FAILURE event
					return;
				})
				.finally(() => {
					/*
					 * If handleRedirectPromise returns a cached promise the necessary events may not be fired
					 * This is a fallback to prevent inProgress from getting stuck in 'startup'
					 */
					if (state.inProgress === InteractionStatus.Startup) {
						state.inProgress = InteractionStatus.None;
						state.logger.info(
							"MsalProvider - handleRedirectPromise resolved, setting inProgress to 'none'",
						);
					}
				});
		});
	},
};
