export enum AmountUnit {
	GRAMS = "GRAMS",
	KILOGRAMS = "KILOGRAMS",
	OUNCES = "OUNCES",
	POUNDS = "POUNDS",
}

export enum CompanyType {
	SUPPLIER = "SUPPLIER",
	MANUFACTURER = "MANUFACTURER",
}

export enum JobPosition {
	PURCHASING = "PURCHASING",
	QUALITY_CONTROL = "QUALITY_CONTROL",
	QUALITY_ASSURANCE = "QUALITY_ASSURANCE",
	RESEARCH_AND_DEVELOPMENT = "RESEARCH_AND_DEVELOPMENT",
	MANUFACTURING_OR_PRODUCTION = "MANUFACTURING_OR_PRODUCTION",
	NPF_OR_DEVELOPMENT = "NPF_OR_DEVELOPMENT",
	MARKETING_AND_SALES = "MARKETING_AND_SALES",
	OTHER = "OTHER",
}

export enum CompanyEmployeeCount {
	NOT_SET = "NOT_SET",
	FROM_0_TO_10 = "FROM_0_TO_10",
	FROM_10_TO_100 = "FROM_10_TO_100",
	FROM_100_TO_250 = "FROM_100_TO_250",
	FROM_250_TO_1000 = "FROM_250_TO_1000",
	MORE_THAN_1000 = "MORE_THAN_1000",
}
