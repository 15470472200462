<template>
	<footer class="mx-auto flex w-full max-w-screen-md flex-col gap-4 p-4" v-bind="$attrs">
		<Separator />

		<div class="flex flex-wrap items-center justify-center gap-4">
			<Button
				:as="RouterLink"
				:to="{ name: 'Disclaimer' }"
				variant="link"
				size="auto"
				class="text-brand-gray"
				>Disclaimer</Button
			>
			<Button
				as="a"
				href="https://www.corbion.com/en/Request-a-sample"
				variant="link"
				size="auto"
				class="text-brand-gray"
				>Sample request</Button
			>
			<Button
				as="a"
				href="https://www.corbion.com/Subscriptions"
				variant="link"
				size="auto"
				class="text-brand-gray"
			>
				Keep me updated
			</Button>
			<ButtonModalShareThisTool v-if="isAuthenticated" class="text-brand-gray" />
		</div>
	</footer>
</template>

<script setup lang="ts">
import { useIsAuthenticated } from "@stijlbreuk/msal-vue";
import { RouterLink } from "vue-router";
import ButtonModalShareThisTool from "@/components/ButtonModal/ShareThisTool.vue";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";

const isAuthenticated = useIsAuthenticated();
</script>
