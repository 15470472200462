<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { Check } from "lucide-vue-next";
import {
	SelectItem,
	SelectItemIndicator,
	type SelectItemProps,
	SelectItemText,
	useForwardProps,
} from "radix-vue";
import { cx } from "@/lib/style/cva.config";

const props = defineProps<SelectItemProps & { class?: HTMLAttributes["class"] }>();

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
	<SelectItem
		v-bind="forwardedProps"
		:class="
			cx(
				'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-neutral-100 focus:text-neutral-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-neutral-800 dark:focus:text-neutral-50',
				props.class,
			)
		"
	>
		<span class="absolute right-2 flex size-3.5 items-center justify-center">
			<SelectItemIndicator>
				<Check class="size-4" />
			</SelectItemIndicator>
		</span>

		<SelectItemText>
			<slot />
		</SelectItemText>
	</SelectItem>
</template>
