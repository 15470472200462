<template>
	<VDropdown
		:shown="showOutOfBounds || showError"
		:theme="getTheme"
		:placement="placement"
		:skidding="skidding"
	>
		<div class="sb_input">
			<div v-if="!!$slots['before']" class="sb_input_before">
				<slot name="before"></slot>
			</div>

			<input
				v-bind="$attrs"
				:id="id"
				:type="type"
				:min="min"
				:max="max"
				:step="step"
				:data-error="showError"
				:data-error-message="errorMessage"
				:required="required"
				class="sb_input_element"
				:value="value"
				:class="{ 'sb_input_element--out-of-bounds': showOutOfBounds }"
				@input="handle"
				@blur="handleBlur"
			/>

			<div v-if="!!$slots['after']" class="sb_input_after">
				<slot name="after"></slot>
			</div>
		</div>

		<template #popper>
			<sb-text v-if="errorMessage" type="p">{{ errorMessage }}</sb-text>

			<div
				v-if="showOutOfBounds"
				class="sb_input_tooltip"
				role="note"
				:aria-label="`Out of bounds for ${$attrs['aria-label']}`"
			>
				<sb-text type="p">{{ rangeText }}</sb-text>

				<sb-button
					v-if="min !== undefined"
					class="sb_input_tooltip_button sb_input_tooltip_button--min"
					button-style="outline"
					@click="() => changeOutOfBounds(min)"
				>
					Set to minimum of {{ min }}

					<template #after>
						<sb-icon name="chevron-right-squared" />
					</template>
				</sb-button>

				<sb-button
					v-if="max !== undefined"
					class="sb_input_tooltip_button sb_input_tooltip_button--max"
					button-style="outline"
					@click="() => changeOutOfBounds(max)"
				>
					Set to maximum of {{ max }}
					<template #after>
						<sb-icon name="chevron-right-squared" />
					</template>
				</sb-button>
			</div>
		</template>
	</VDropdown>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch } from "vue";
import type { Placement } from "floating-vue";
import SbButton from "@/components/atoms/Button.vue";
import SbIcon from "@/components/atoms/Icon.vue";
import SbText from "@/components/atoms/Text.vue";
import { countDecimals } from "@/utils";

const props = withDefaults(
	defineProps<{
		id?: string;
		type?:
			| "text"
			| "email"
			| "date"
			| "datetime-local"
			| "month"
			| "number"
			| "password"
			| "search"
			| "tel"
			| "url"
			| "week";
		min?: number;
		rangeText?: string;
		max?: number;
		step?: number;
		forceStep?: boolean;
		required?: boolean;
		error?: boolean;
		errorMessage?: string;
		modelValue: string | number | null;
		placement?: Placement;
		skidding?: number;
		supressError?: boolean;
		allowNull?: boolean;
	}>(),
	{
		id: `sb_input_${crypto.randomUUID()}`,
		type: "text",
		min: undefined,
		rangeText: "Your value is out of bounds.",
		max: undefined,
		step: undefined,
		forceStep: false,
		required: false,
		error: false,
		errorMessage: undefined,
		placement: "auto",
		skidding: 0,
		supressError: false,
		allowNull: false,
	},
);

const {
	type,
	min,
	max,
	forceStep,
	step,
	modelValue,
	error,
	errorMessage,
	supressError: initialSupressError,
} = toRefs(props);

const emits = defineEmits<{
	(e: "update:modelValue", value: never): void;
}>();

// eslint-disable-next-line vue/no-dupe-keys
const supressError = ref<boolean>(initialSupressError.value);

const value = ref<string | number | null>(modelValue.value);
watch(modelValue, (newValue) => {
	value.value = newValue;
});

watch(errorMessage, () => {
	// supressError.value = false;
});

watch(initialSupressError, (newValue) => {
	supressError.value = newValue;
});

const showOutOfBounds = computed(() => {
	let exceedsMinimum = false;
	let exceedsMaximum = false;

	if (value.value === null) {
		return false;
	}

	if (min?.value !== undefined) {
		exceedsMinimum = Number(value.value) < min.value;
	}
	if (max?.value !== undefined) {
		exceedsMaximum = Number(value.value) > max.value;
	}

	return (exceedsMinimum || exceedsMaximum) && !supressError.value;
});

const showError = computed(
	() => (error.value || !!errorMessage.value) && !!errorMessage.value && !supressError.value,
);

const getTheme = computed(() => {
	let theme = "input";

	// if (inModal.value) {
	//   theme += '-modal';
	// }

	if (showError.value) {
		theme += "-error";
	}

	return theme;
});

const handle = (event: Event) => {
	if (event.target) {
		const element = event.target as HTMLInputElement;

		supressError.value = true;

		if (!element.value && props.allowNull) {
			update(null as any);
			return;
		}

		update(element.value);
	}
};

const handleBlur = () => {
	if (type.value === "number" && !forceStep.value) {
		update(Number(modelValue.value));
	}

	supressError.value = false;

	checkForcedStep();
};

const checkForcedStep = () => {
	if (type.value !== "number") {
		return;
	}

	if (!forceStep.value) {
		return;
	}

	if (value.value === null) {
		return;
	}

	const numberValue = Number(value.value);

	const stepValue = step.value ?? 1;
	const newValue = numberValue + stepValue / 2 - ((numberValue + stepValue / 2) % stepValue);

	const decimals = countDecimals(stepValue);
	const multiplier = Math.pow(10, decimals);

	// Get nearest valid value with the step
	const roundedValue = Math.round((newValue + Number.EPSILON) * multiplier) / multiplier;

	update(roundedValue);
};

const changeOutOfBounds = (number: number | undefined) => {
	if (number !== undefined) {
		update(number);
	}
};

const update = (newValue: string | number) => {
	value.value = newValue;
	emits("update:modelValue", newValue as never);
};
</script>

<style lang="scss">
.sb_input {
	--input-size: 32px;
	--input-width: 100%;
	--border-color: #{$border-color};

	display: flex;
	gap: $padding * 0.5;
	align-items: center;
	height: var(--input-size);

	&_element {
		font-size: $font-size;
		line-height: $font-size;
		display: block;
		height: 95%; // To make sure borders are visible
		width: var(--input-width);
		background: none;
		appearance: none;
		border-width: $border-width * 2;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		border-top-width: 0;
		border-style: $border-style;
		border-color: var(--border-color);
		margin-block-start: $border-width * 2;
		padding: $padding * 0.5;
		color: $text-color;
		outline: none;
		transition-duration: $transition-duration-short;
		transition-property: color, background, border-color;
		transition-timing-function: $transition-timing-function;

		&::placeholder {
			color: $brand-gray;
		}

		&:disabled,
		&[disabled="true"] {
			opacity: 1;
			background: $brand-light-gray;
			cursor: not-allowed;
		}

		&--out-of-bounds,
		&[data-error="true"] {
			border-color: $brand-error;
		}
	}

	&_before,
	&_after {
		color: $brand-gray;
	}

	&_tooltip {
		display: flex;
		flex-direction: column;
		gap: $padding * 0.5;
		max-width: 30ch;
	}
}
</style>
