<template>
	<Dialog v-model:open="dialogOpen">
		<DialogTrigger as-child>
			<Button variant="link" size="auto" class="gap-2 text-base" v-bind="$attrs">
				Tutorials
			</Button>
		</DialogTrigger>
		<DialogContent>
			<DialogHeader>
				<DialogTitle class="text-2xl">Tutorials</DialogTitle>
				<DialogDescription>
					Looking for help within the tool? You can use tutorials to guide you through the
					application.
				</DialogDescription>
			</DialogHeader>

			<div class="flex flex-col gap-3">
				<div
					v-for="tutorial in tutorials"
					:key="tutorial.key"
					class="flex items-center justify-between gap-4"
				>
					<div class="flex flex-1 flex-col gap-1">
						<Label> {{ tutorial.label }} </Label>
						<Text v-if="tutorial.description" variant="muted" class="text-xs">
							{{ tutorial.description }}
						</Text>
					</div>

					<DialogClose as-child>
						<Button
							class="gap-2 bg-brand-primary-lighter hover:bg-brand-primary-lighter/90"
							:aria-label="`Start ${tutorial.label} tutorial`"
							@click="start(tutorial.key)"
						>
							Start
							<ArrowRight class="size-4" />
						</Button>
					</DialogClose>
				</div>
			</div>
		</DialogContent>
	</Dialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { ArrowRight } from "lucide-vue-next";
import { useRouter } from "vue-router";
import { Button } from "@/components/ui/button";
import Text from "@/components/ui/custom/Text.vue";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { startOnboarding } from "@/tours/onboarding";

const props = withDefaults(
	defineProps<{
		/** The controlled open state of the dialog. Can be binded as `v-model:open`. */ open?: boolean;
	}>(),
	{
		open: undefined,
	},
);
const emits = defineEmits<{ "update:open": [value: boolean] }>();

const internalOpen = ref<boolean>(props.open ?? false);
const dialogOpen = computed({
	get: () => props.open ?? internalOpen.value,
	set: (newValue) => {
		emits("update:open", newValue ?? false);
		internalOpen.value = newValue;
	},
});

const router = useRouter();

const tutorials = [
	{
		key: "general",
		label: "General",
		description: "This tutorial will guide you through the basics of the application.",
	},
] as const satisfies ReadonlyArray<{ key: string; label: string; description?: string }>;

function start(key: (typeof tutorials)[number]["key"]) {
	switch (key) {
		case "general":
		default: {
			if (router.currentRoute.value.name !== "Calculate") {
				router.push({ name: "Calculate" });
			}

			startOnboarding();
			return;
		}
	}
}
</script>
