export const graphqlErrorMap: Record<string, string> = {
	// Model not found exceptions
	NMIProductNotFoundException: "Product not found.",
	AntimicrobialProductNotFoundException: "Product not found.",
	CorbionProductNotFoundException: "Product not found.",
	PureProductNotFoundException: "Product not found.",
	PrototypeProductNotFoundException: "Product not found.",
	MoldGroupResultNotFoundException: "Mold group result not found.",
	MoldResultNotFoundException: "Mold result not found.",
	FormulationResultNotFoundException: "Formulation result not found.",

	UnauthorizedException: "You are not authorized to perform this action.",
	// ForbiddenException: 'You are not authorized to perform this action.',
	UniqueConstraintException: "This item seems to already exist.",
	InvalidAmountOfFormulationsException: "Invalid amount of formulations used.",
	ClientHandleHeaderRequiredException:
		"Something went wrong identifying which calculator you are using. Please try again later.",
	BadRequestException: "The request is invalid.",
	InternalServerException: "Something went wrong on our end. Please try again later.",
};

// Straincode: text
export const moldInfoTextMap: Record<string, string> = {
	"AC-001":
		"This <i>%NAME%</i> belongs to low moisture tolerant molds that was isolated from icing. This is a worldwide distributed fungus common in indoor air and can tolerate low water activities.",
	"AF-001":
		"This <i>%NAME%</i> belongs to common food spoilage molds that was isolated from bread. This is a common widespread fungus and can tolerate rather low water activities.",
	"AM-001":
		"This <i>%NAME%</i> belongs to low moisture tolerant molds that was isolated from icing. This is a worldwide distributed fungus common in indoor air and can tolerate low water activities.",
	"AN-001":
		"This <i>%NAME%</i> belongs to common food spoilage molds that was isolated from bread. This is a common world wide fungus that can tolerate low pH values and rather low water activities.",
	"AP-001":
		"This <i>%NAME%</i> belongs to low moisture tolerant molds that was isolated from liquorice. This is a worldwide distributed fungus common in indoor air and can tolerate low water activities. ",
	"AR-001":
		"This <i>%NAME%</i> belongs to low moisture tolerant molds that was isolated from gingerbread. This is a worldwide distributed fungus common in indoor air and can tolerate low water activities.",
	"CH-001":
		"This <i>%NAME%</i> belongs to common food spoilage molds that was isolated from margarine. This is tolerant of environments and products with low water activity.",
	"CS-001":
		"This <i>%NAME%</i> belongs to common food spoilage molds that was isolated from bread. This is tolerant of environments and products with low water activity.",
	"FO-001":
		"This <i>%NAME%</i> belongs to common food spoilage molds that was isolated from an unknown source. This is a worldwide fungus in food, plants and indoor environments and can grow under anaerobic conditions.",
	"PC-001":
		"This <i>%NAME%</i> belongs to common food spoilage molds that was isolated from bread. This is a common species in indoor air and occurs on many different kinds of food.",
	"PR-001":
		"This <i>%NAME%</i> belongs to preservative tolerant molds that was isolated from white bread. This is a widespread fungus, resistant to organic acids and difficult to control.",
	"PR-002":
		"This <i>%NAME%</i> belongs to preservative tolerant molds that was isolated from a bakery air sample. This is a widespread fungus, resistant to organic acids and difficult to control.",
	"PR-003":
		"This <i>%NAME%</i> belongs to preservative tolerant molds that was isolated from a bakery air sample. This is a widespread fungus, resistant to organic acids and difficult to control.",
	"PR-004":
		"This <i>%NAME%</i> belongs to preservative tolerant molds that was isolated from rye bread. This is a widespread fungus, resistant to organic acids and difficult to control.",
	"PR-005":
		"This <i>%NAME%</i> belongs to preservative tolerant molds that was isolated from bread. This is a widespread fungus, resistant to organic acids and difficult to control.",
};
