// Based on https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/src/hooks/useIsAuthenticated.ts
import { computed } from "vue";
import type { ComputedRef } from "vue";
import { type AccountInfo, InteractionStatus } from "@azure/msal-browser";
import type { AccountIdentifiers } from "../types/AccountIdentifiers";
import { getAccountByIdentifiers } from "../utils/utilities";
import { useMsal } from "./useMsal";

function isAuthenticated(allAccounts: AccountInfo[], matchAccount?: AccountIdentifiers): boolean {
	if (
		matchAccount &&
		(matchAccount.username || matchAccount.homeAccountId || matchAccount.localAccountId)
	) {
		return !!getAccountByIdentifiers(allAccounts, matchAccount);
	}

	return allAccounts.length > 0;
}

/**
 * Returns whether or not a user is currently signed-in. Optionally provide 1 or more
 * accountIdentifiers to determine if a specific user is signed-in
 *
 * @param matchAccount
 */
export function useIsAuthenticated(matchAccount?: AccountIdentifiers): ComputedRef<boolean> {
	const { accounts: allAccounts, inProgress } = useMsal();

	const hasAuthenticated = computed(() => {
		if (inProgress.value === InteractionStatus.Startup) {
			return false;
		}

		return isAuthenticated(allAccounts.value, matchAccount);
	});

	return hasAuthenticated;
}
