// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/vue/
import type { App } from "vue";
import * as Sentry from "@sentry/vue";
import type { Router } from "vue-router";
import { clientEnv } from "@/lib/env/client.mjs";

export function setupSentry({ app, router }: { app: App<Element>; router: Router }) {
	Sentry.init({
		app,
		dsn: clientEnv.VITE_SENTRY_DSN,
		environment: clientEnv.MODE,

		tracesSampleRate: clientEnv.VITE_SENTRY_TRACES_SAMPLE_RATE,
		replaysSessionSampleRate: clientEnv.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
		replaysOnErrorSampleRate: clientEnv.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,

		enabled: clientEnv.DEV ? false : true,
		// Setting this option to true will print useful information to the console while you're setting up Sentry.
		...(clientEnv.VITE_DEBUG && { debug: true, enabled: true }),

		/**
		 * Adds traces to all outgoing XHR/fetch requests to the API endpoint
		 *
		 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/performance/instrumentation/automatic-instrumentation/#tracepropagationtargets
		 */
		tracePropagationTargets: [
			"localhost",
			/^\//,
			// Known corbion api endpoints
			/^https:\/\/api\.calculators-dev\.corbion\.com\/$/,
			/^https:\/\/api\.calculators-acc\.corbion\.com\/$/,
			/^https:\/\/api\.calculators\.corbion\.com\/$/,
			clientEnv.VITE_CORBION_API_ENDPOINT,
		].filter(Boolean),

		integrations: [
			Sentry.replayIntegration(),
			Sentry.vueIntegration({ app }),
			Sentry.browserTracingIntegration({ router }),
		],
	});
}
