<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
	SelectContent,
	type SelectContentEmits,
	type SelectContentProps,
	SelectPortal,
	SelectViewport,
	useForwardPropsEmits,
} from "radix-vue";
import { cx } from "@/lib/style/cva.config";
import { SelectScrollDownButton, SelectScrollUpButton } from ".";

defineOptions({
	inheritAttrs: false,
});

const props = withDefaults(
	defineProps<SelectContentProps & { class?: HTMLAttributes["class"] }>(),
	{
		position: "popper",
		class: undefined,
	},
);
const emits = defineEmits<SelectContentEmits>();

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<SelectPortal>
		<SelectContent
			v-bind="{ ...forwarded, ...$attrs }"
			:class="
				cx(
					'relative z-50 max-h-96 min-w-32 overflow-hidden rounded-md border border-neutral-200 bg-white text-neutral-950 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-neutral-800 dark:bg-neutral-950 dark:text-neutral-50',
					position === 'popper' &&
						'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
					props.class,
				)
			"
		>
			<SelectScrollUpButton />
			<SelectViewport
				:class="
					cx(
						'p-1',
						position === 'popper' &&
							'h-[--radix-select-trigger-height] w-full min-w-[--radix-select-trigger-width]',
					)
				"
			>
				<slot />
			</SelectViewport>
			<SelectScrollDownButton />
		</SelectContent>
	</SelectPortal>
</template>
