import { computed, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { z } from "zod";
import { calculation } from "@/store/calculation/state";
import { useToursStore } from "@/store/tours";

export const MiscSchema = z.object({
	compactMode: z.coerce.boolean().default(false),
	resultsClosed: z.coerce.boolean().default(true),
});

export const useMiscStore = defineStore(
	"misc",
	() => {
		const { active: activeTour } = storeToRefs(useToursStore());

		const compactMode = ref(MiscSchema.shape.compactMode.parse(undefined));
		const resultsClosed = ref(MiscSchema.shape.resultsClosed.parse(undefined));

		const resultsShown = computed<boolean>(() => {
			if (activeTour.value) {
				return true;
			}

			return (
				!resultsClosed.value &&
				!calculation.validating.validated &&
				(calculation.calculating || !!calculation.result)
			);
		});

		return { compactMode, resultsClosed, resultsShown };
	},
	{
		persist: {
			paths: ["compactMode"],
			serializer: {
				deserialize: (value) => MiscSchema.parse(JSON.parse(value)),
				serialize: (value) => JSON.stringify(value),
			},
		},
	},
);
