import type { UnitOfWeight } from "@/graphql/graphql";

export const UNIT_OF_WEIGHT = {
	GRAMS: {
		label: "Grams",
		unit: "g",
	},
	KILO_GRAMS: {
		label: "Kilograms",
		unit: "kg",
	},
	OUNCES: {
		label: "Ounces",
		unit: "oz",
	},
	POUNDS: {
		label: "Pounds",
		unit: "lbs",
	},
} as const satisfies Record<UnitOfWeight, { label: string; unit: string }>;
