<template>
	<VDropdown :shown="showError" :theme="getTheme">
		<div class="sb_select">
			<select
				:id="id"
				class="sb_select_element"
				:value="value"
				v-bind="$attrs"
				:data-error="showError"
				:data-error-message="errorMessage"
				@input="handle"
			>
				<option v-if="placeholder" value="" disabled>{{ placeholder }}</option>
				<option v-for="option in options" :key="option.value" :value="option.value">
					{{ option.label }}
				</option>
			</select>

			<sb-icon class="sb_select_icon" name="dropdown" />
		</div>

		<template #popper>
			<sb-text v-if="errorMessage" type="p">{{ errorMessage }}</sb-text>
		</template>
	</VDropdown>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch } from "vue";
import SbIcon from "@/components/atoms/Icon.vue";
import sbText from "@/components/atoms/Text.vue";

const props = withDefaults(
	defineProps<{
		id?: string;
		placeholder?: string;
		error?: boolean;
		errorMessage?: string;
		inModal?: boolean;
		tooltipLocation?: "inline-start" | "inline-end";
		modelValue: string;
		options: Array<{ value: string | number; label: string }>;
	}>(),
	{
		id: `sb_select_${crypto.randomUUID()}`,
		placeholder: undefined,
		error: false,
		errorMessage: undefined,
		inModal: false,
		tooltipLocation: "inline-start",
	},
);

const { modelValue, error, tooltipLocation, inModal, errorMessage } = toRefs(props);

const emits = defineEmits<{
	(e: "update:modelValue", value: never): void;
}>();

const supressError = ref<boolean>(false);

const value = ref<string | number>(modelValue.value);
watch(modelValue, (newValue) => {
	value.value = newValue;
});

watch(errorMessage, () => {
	supressError.value = false;
});

const showError = computed(
	() => (error.value || !!errorMessage.value) && !!errorMessage.value && !supressError.value,
);

const getTheme = computed(() => {
	let theme = "input";

	if (inModal.value) {
		theme += "-modal";
	}

	if (showError.value) {
		theme += "-error";
	}

	theme += `-${tooltipLocation.value}`;

	return theme;
});

const handle = (event: Event) => {
	if (event.target) {
		const element = event.target as HTMLInputElement;

		supressError.value = true;
		value.value = element.value;
		emits("update:modelValue", element.value as never);
	}
};
</script>

<style lang="scss">
.sb_select {
	--select-size: 32px;

	position: relative;
	display: flex;
	gap: $padding * 0.5;
	align-items: center;
	height: var(--select-size);

	&_element {
		cursor: pointer;
		font-size: $font-size;
		line-height: $font-size;
		display: block;
		height: 100%;
		width: 100%;
		background: none;
		appearance: none;
		border-width: $border-width * 2;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		border-top-width: 0;
		border-style: $border-style;
		border-color: $border-color;
		margin-block-start: $border-width * 2;
		padding-inline-start: $padding * 0.5;
		padding-inline-end: $padding * 2;
		color: $text-color;
		outline: none;
		transition-duration: $transition-duration-short;
		transition-property: color, background, border-color;
		transition-timing-function: $transition-timing-function;

		&::placeholder {
			color: $brand-gray;
		}

		&:disabled,
		&[disabled="true"] {
			opacity: 1;
			background: $brand-light-gray;
			cursor: not-allowed;
		}

		&[data-error="true"] {
			border-color: $brand-error;
		}
	}

	&_icon {
		position: absolute;
		top: 0;
		right: $padding * 0.5;
		bottom: 0;
		pointer-events: none;
		height: 100%;
		transform: scale(0.75);

		* {
			color: $border-color;
		}
	}
}
</style>
