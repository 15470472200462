import { autoPlacement, offset } from "@floating-ui/dom";
import Shepherd from "shepherd.js";
import { addFormulation } from "@/store/calculation/actions";
import { REFERENCE_FORMULATION_ID, useFormulationsStore } from "@/store/formulations";
import { useToursStore } from "@/store/tours";
import { handleTourComplete, handleTourNext } from "@/tours";

const ButtonClasses = {
	Skip: "sb_button sb_button--outline sb_button_skip",
	Start: "sb_button sb_button--primary",
	Previous: "sb_button sb_button--outline sb_button_previous",
	Next: "sb_button sb_button--primary",
	Finish: "sb_button sb_button--primary",
};

const ButtonText = {
	Skip: "Skip",
	Start:
		'<span>Start</span><span class="sb_icon"><svg role="img"><use xlink:href="#icon_chevron-right-squared" /></svg></span>',
	Previous: "Previous",
	Next: '<span>Next</span><span class="sb_icon"><svg role="img"><use xlink:href="#icon_chevron-right-squared" /></svg></span>',
	Finish:
		'<span>Finish</span><span class="sb_icon"><svg role="img"><use xlink:href="#icon_chevron-right-squared" /></svg></span>',
};

function navigateToYourReferenceTab() {
	const elementClass = "sb_calculate_tabs_tab--control";
	const element = document.querySelector(`.${elementClass}`);
	element?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
}

export const onboardingTour = new Shepherd.Tour({
	tourName: "onboarding",
	confirmCancel: false,
	useModalOverlay: true,
	exitOnEsc: true,
	keyboardNavigation: true,
	classPrefix: "sb-onboarding",
	defaultStepOptions: {
		arrow: false,
		classes: "sb_onboarding",
		modalOverlayOpeningPadding: 4,
		modalOverlayOpeningRadius: 4,
		scrollTo: { behavior: "smooth", block: "nearest", inline: "nearest" },
		cancelIcon: { enabled: true },
		floatingUIOptions: {
			middleware: [offset(8), autoPlacement()],
		},
	},
	steps: [
		{
			id: "step0",
			...({
				title: "Welcome!",
				text: "It looks like you are a first time user, do you want a general tutorial?",
				showOn: () => !useToursStore().onboarding.started,
				beforeShowPromise: async () => useToursStore().$patch({ onboarding: { started: true } }),
				buttons: [
					{
						text: ButtonText.Skip,
						classes: ButtonClasses.Skip,
						action: function () {
							return this.cancel();
						},
					},
					{
						text: ButtonText.Start,
						classes: ButtonClasses.Start,
						action: function () {
							return handleTourNext(this, { tour: "onboarding", step: "step0" });
						},
					},
				],
			} satisfies Shepherd.Step.StepOptions),
		},
		{
			id: "step1",
			...({
				title: "Introduction",
				text: "The Corbion Natural Mold Inhibition model is an easy-to-use tool full of valuable data for product developers looking to accurately project mold growth for the desired application shelf life. With this tool you can: 1) shorten your product development timeline and 2) avoid excessive ingredient spend with optimized formulations by reducing the number of challenge studies required to reach your optimal product.",
				buttons: [
					{
						text: ButtonText.Skip,
						classes: ButtonClasses.Skip,
						action: function () {
							return this.cancel();
						},
					},
					{
						text: ButtonText.Next,
						classes: ButtonClasses.Next,
						action: function () {
							return handleTourNext(this, { tour: "onboarding", step: "step1" });
						},
					},
				],
			} satisfies Shepherd.Step.StepOptions),
		},
		{
			id: "step2",
			...({
				title: "Reference formulation",
				text: "Your reference formulation can be compared to up to three other formulations.",
				attachTo: {
					element: ".sb_calculate_tabs_tab--control",
					on: "bottom",
				},
				buttons: [
					{
						text: ButtonText.Previous,
						classes: ButtonClasses.Previous,
						action: function () {
							return this.back();
						},
					},
					{
						text: ButtonText.Next,
						classes: ButtonClasses.Next,
						action: function () {
							return handleTourNext(this, { tour: "onboarding", step: "step2" });
						},
					},
				],
			} satisfies Shepherd.Step.StepOptions),
		},
		{
			id: "step3",
			...({
				title: "Formulation parameter inputs (for Bread & buns)",
				text: "You can provide the amount of flour, total batch weight, and bake loss of your formulation. With this information we will calculate the amount of the ingredients in the finished product. You can change the unit in the settings in the status bar at the bottom of the screen.",
				attachTo: {
					element: "#formulation-parameters",
					on: "bottom",
				},
				buttons: [
					{
						text: ButtonText.Previous,
						classes: ButtonClasses.Previous,
						action: function () {
							return this.back();
						},
					},
					{
						text: ButtonText.Next,
						classes: ButtonClasses.Next,
						action: function () {
							return handleTourNext(this, { tour: "onboarding", step: "step3" });
						},
					},
				],
			} satisfies Shepherd.Step.StepOptions),
		},

		{
			id: "step4",
			...({
				title: "Ingredients",
				text: "You can select ingredients for your reference and compared formulations.",
				attachTo: {
					element: "[data-shepherd-ingredients]",
					on: "bottom",
				},
				buttons: [
					{
						text: ButtonText.Previous,
						classes: ButtonClasses.Previous,
						action: function () {
							return this.back();
						},
					},
					{
						text: ButtonText.Next,
						classes: ButtonClasses.Next,
						action: function () {
							return handleTourNext(this, { tour: "onboarding", step: "step4" });
						},
					},
				],
			} satisfies Shepherd.Step.StepOptions),
		},
		{
			id: "step5",
			...({
				title: "Food characteristics",
				text: "We base our prediction on several characteristics of your product. A default value is provided, but you can change these according to your specific food characteristics.",
				attachTo: {
					element: "[data-shepherd-food-characteristics]",
					on: "bottom",
				},
				buttons: [
					{
						text: ButtonText.Previous,
						classes: ButtonClasses.Previous,
						action: function () {
							return this.back();
						},
					},
					{
						text: ButtonText.Next,
						classes: ButtonClasses.Next,
						action: function () {
							return handleTourNext(this, { tour: "onboarding", step: "step5" });
						},
					},
				],
			} satisfies Shepherd.Step.StepOptions),
		},
		{
			id: "step6",
			...({
				title: "Create your own formulation",
				text: "You can enter different formulation (s) to compare to the reference formulation.",
				attachTo: {
					element: ".sb_calculate_tabs_tab--add",
					on: "bottom",
				},
				buttons: [
					{
						text: ButtonText.Previous,
						classes: ButtonClasses.Previous,
						action: function () {
							return this.back();
						},
					},
					{
						text: ButtonText.Next,
						classes: ButtonClasses.Next,
						action: function () {
							return handleTourNext(this, { tour: "onboarding", step: "step6" });
						},
					},
				],
			} satisfies Shepherd.Step.StepOptions),
		},
		{
			id: "step7",
			...({
				title: "Results",
				text: "The model plots days to visible mold growth for relevant molds based on the bread characteristics entered. Predictions are estimated based on the reference formulation. The graphs show the relative days to visible mold growth for each formulation and each selected mold/group compared to the reference.",
				attachTo: {
					element: "[data-shepherd-results]",
					on: "bottom",
				},
				buttons: [
					{
						text: ButtonText.Previous,
						classes: ButtonClasses.Previous,
						action: function () {
							return this.back();
						},
					},
					{
						text: ButtonText.Finish,
						classes: ButtonClasses.Finish,
						action: function () {
							return handleTourComplete(this, { tour: "onboarding", step: "step7" });
						},
					},
				],
			} satisfies Shepherd.Step.StepOptions),
		},
	],
});

["complete", "cancel", "start", "active", "inactive"].forEach((event) =>
	onboardingTour.on(event, () => {
		const toursStore = useToursStore();

		switch (event) {
			case "complete":
				navigateToYourReferenceTab();
				break;
			case "cancel":
				toursStore.$patch({ onboarding: { active: false, completed: true } });
				navigateToYourReferenceTab();
				break;

			case "start":
			case "active":
				toursStore.$patch({ onboarding: { active: true } });
				break;

			case "inactive":
				toursStore.$patch({ onboarding: { active: false } });
				break;

			default:
				break;
		}
	}),
);

export function startOnboarding() {
	onboardingTour.start();

	const formulationsStore = useFormulationsStore();
	const { formulations, selected } = formulationsStore;

	if (formulations.length <= 1) {
		addFormulation(true);
	}

	if (selected === REFERENCE_FORMULATION_ID) {
		formulationsStore.$patch({ selected: formulations[formulations.length - 1].id });
	}
}
