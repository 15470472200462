<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { Label, type LabelProps } from "radix-vue";
import { cx } from "@/lib/style/cva.config";

const props = withDefaults(
	defineProps<LabelProps & { class?: HTMLAttributes["class"]; withStyles?: boolean }>(),
	{
		class: undefined,
		withStyles: true,
	},
);

const delegatedProps = computed(() => {
	const { class: _, withStyles, ...delegated } = props;

	return delegated;
});
</script>

<template>
	<Label
		v-bind="delegatedProps"
		:class="
			cx(
				'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
				withStyles && 'font-bold uppercase tracking-widest',
				props.class,
			)
		"
	>
		<slot />
	</Label>
</template>
