<template>
	<sb-svg-symbols />

	<TooltipProvider>
		<div
			:class="
				cx(
					'sb_app bg-[--app-background,theme(colors.white)] transition-[background,padding-bottom] will-change-[padding-bottom]',
					!!route.meta.showFixedActionbar && 'pb-16 [--app-background:theme(colors.neutral.50)]',
				)
			"
			:data-compact="compactMode ? 'enabled' : 'disabled'"
			:data-results="resultsShown ? 'shown' : 'hidden'"
		>
			<Header v-if="!route.meta.hideHeader" />

			<authenticated-template
				v-if="route.meta.requiresAuth"
				:home-account-id="token?.account?.homeAccountId"
				:local-account-id="token?.account?.localAccountId"
				:username="token?.account?.username"
			>
				<router-view v-slot="{ Component }">
					<transition :name="transitionName" mode="out-in">
						<component :is="Component" />
					</transition>
				</router-view>
			</authenticated-template>
			<unauthenticated-template v-else-if="route.meta.requiresUnauth">
				<router-view v-slot="{ Component }">
					<transition :name="transitionName" mode="out-in">
						<component :is="Component" />
					</transition>
				</router-view>
			</unauthenticated-template>
			<router-view v-else v-slot="{ Component }">
				<transition :name="transitionName" mode="out-in">
					<component :is="Component" />
				</transition>
			</router-view>

			<Footer v-if="!route.meta.hideFooter" />

			<transition name="slide-in-bottom">
				<sb-fixed-action-bar v-if="route.meta.showFixedActionbar" />
			</transition>
		</div>
	</TooltipProvider>

	<sb-confirmation-dialog />
	<sb-privacy-statement-alert />

	<Toaster rich-colors close-button position="bottom-right" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import AuthenticatedTemplate from "@stijlbreuk/msal-vue/components/AuthenticatedTemplate.vue";
import UnauthenticatedTemplate from "@stijlbreuk/msal-vue/components/UnauthenticatedTemplate.vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import SbSvgSymbols from "@/components/icons/SvgSymbols.vue";
import SbPrivacyStatementAlert from "@/components/modals/PrivacyStatementAlert.vue";
import SbConfirmationDialog from "@/components/organisms/ConfirmationDialog.vue";
import SbFixedActionBar from "@/components/organisms/FixedActionBar.vue";
import { Toaster } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { cx } from "@/lib/style/cva.config";
import { useAuthStore } from "@/store/auth";
import { useMiscStore } from "@/store/misc";

const { token } = storeToRefs(useAuthStore());
const route = useRoute();
const transitionName = ref<string>("router-fade");
const { compactMode, resultsShown } = storeToRefs(useMiscStore());
</script>

<style scoped lang="scss">
.router-fade-enter-active,
.router-fade-leave-active {
	transition-property: opacity;
	transition-timing-function: $transition-timing-function;
	transition-duration: $transition-duration;
}

.router-fade-enter-from,
.router-fade-leave-to {
	opacity: 0;
}

.slide-in-bottom-enter-active,
.slide-in-bottom-leave-active {
	will-change: transform;
	transition-property: transform;
	transition-timing-function: $transition-timing-function;
	transition-duration: $transition-duration-short;
}

.slide-in-bottom-enter-from,
.slide-in-bottom-leave-to {
	transform: translateY(100%);
}
</style>
