import type { App } from "vue";
// Atoms
import SbButton from "@/components/atoms/Button.vue";
import SbElevator from "@/components/atoms/Elevator.vue";
import SbIcon from "@/components/atoms/Icon.vue";
import SbInput from "@/components/atoms/Input.vue";
import SbLoadingDots from "@/components/atoms/LoadingDots.vue";
import SbLogo from "@/components/atoms/Logo.vue";
import SbModal from "@/components/atoms/Modal.vue";
import SbMultiselect from "@/components/atoms/Multiselect.vue";
import SbProgressBar from "@/components/atoms/ProgressBar.vue";
import SbRadio from "@/components/atoms/Radio.vue";
import SbSelect from "@/components/atoms/Select.vue";
import SbText from "@/components/atoms/Text.vue";
import SbIconInfo from "@/components/icons/Info.vue";
// Icons
import SbSvgSymbols from "@/components/icons/SvgSymbols.vue";
// Molecules
import SbField from "@/components/molecules/Field.vue";

export function registerComponents(app: App<Element>) {
	// Atoms
	app.component("SbButton", SbButton);
	app.component("SbElevator", SbElevator);
	app.component("SbIcon", SbIcon);
	app.component("SbInput", SbInput);
	app.component("SbLogo", SbLogo);
	app.component("SbModal", SbModal);
	app.component("SbMultiselect", SbMultiselect);
	app.component("SbProgressBar", SbProgressBar);
	app.component("SbRadio", SbRadio);
	app.component("SbSelect", SbSelect);
	app.component("SbText", SbText);

	// Icon
	app.component("SbSvgSymbols", SbSvgSymbols);
	app.component("SbIconInfo", SbIconInfo);
	app.component("SbLoadingDots", SbLoadingDots);

	// Molecules
	app.component("SbField", SbField);
}
