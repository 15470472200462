import {
	type IPublicClientApplication,
	InteractionRequiredAuthError,
	InteractionStatus,
} from "@azure/msal-browser";
import { msalRequest } from "@/lib/services/msal/request";

export async function getToken(
	instance: IPublicClientApplication,
	inProgress: InteractionStatus = InteractionStatus.None,
) {
	const account = instance.getActiveAccount();

	if (inProgress !== InteractionStatus.None) {
		return;
	}

	if (!account) {
		return await instance.acquireTokenRedirect(msalRequest);
	}

	try {
		return await instance.acquireTokenSilent({
			...msalRequest,
			account,
		});
	} catch (error) {
		if (error instanceof InteractionRequiredAuthError) {
			return await instance.acquireTokenRedirect(msalRequest);
		}
	}

	return;
}
