<template>
	<teleport to="body">
		<transition name="confirm-dialog" appear>
			<div v-if="dialogShown" class="sb_confirmation-dialog" role="dialog" v-bind="$attrs">
				<div class="sb_confirmation-dialog_inner">
					<div class="sb_confirmation-dialog_backdrop" @click="dismiss"></div>
					<div class="sb_confirmation-dialog_content">
						<sb-text v-if="dialog.title" type="h3">{{ dialog.title }}</sb-text>

						<sb-text>{{ dialog.message }}</sb-text>

						<div class="sb_confirmation-dialog_content_actions">
							<sb-button button-style="outline" @click="cancel">
								{{ dialog.cancelText ?? "Cancel" }}
							</sb-button>
							<sb-button @click="ok">
								{{ dialog.confirmText ?? "OK" }}
							</sb-button>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</teleport>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import SbButton from "@/components/atoms/Button.vue";
import SbText from "@/components/atoms/Text.vue";
import { useDialogStore } from "@/store/dialog";

const { shown: dialogShown, state: dialog } = storeToRefs(useDialogStore());

const handleKeyDown = (event: KeyboardEvent) => {
	if (event.key === "Escape") {
		dismiss();
	}
};

onMounted(() => {
	window.addEventListener("keydown", handleKeyDown);
});

onUnmounted(() => {
	window.removeEventListener("keydown", handleKeyDown);
});

const ok = () => {
	if (!dialogShown.value) {
		return;
	}

	dialog.value?.onOk();
};

const cancel = () => {
	if (!dialogShown.value) {
		return;
	}

	dialog.value?.onCancel();
};

const dismiss = () => {
	if (!dialogShown.value) {
		return;
	}

	dialog.value?.onDismiss();
};
</script>

<style lang="scss">
.sb_confirmation-dialog {
	--padding: #{$padding};

	position: fixed;
	inset: 0;
	isolation: isolate;
	display: grid;
	place-items: center;
	z-index: 30001;
	padding: $padding;

	&_backdrop {
		position: fixed;
		inset: 0;
		background: rgba($brand-primary, 0.75);
		backdrop-filter: blur(3px);
	}

	&_content {
		padding: $padding;
		border-radius: $border-radius;
		background: $brand-white;
		display: flex;
		flex-direction: column;
		gap: $padding * 0.5;
		max-width: min(40ch, calc(100vw - calc(#{$padding} * 2)));
		margin-inline: auto;
		max-height: calc(100vh - calc(#{$padding} * 2));
		overflow-y: auto;
		width: 100vw;
		will-change: transform;

		@include media(">=md") {
			min-width: 60ch;
			width: 75vw;
		}

		&_actions {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			gap: $padding;
		}
	}
}

.confirm-dialog-enter-active,
.confirm-dialog-leave-active {
	transition-property: opacity;
	transition-timing-function: $transition-timing-function;
	transition-duration: $transition-duration-short;

	.sb_confirmation-dialog_content {
		transition-property: transform;
		transition-timing-function: $transition-timing-function;
		transition-duration: $transition-duration-short;
	}
}

.confirm-dialog-enter-from,
.confirm-dialog-leave-to {
	opacity: 0;

	.sb_confirmation-dialog_content {
		transform: scale(0.9) translateY(-10%) rotateX(45deg);
	}
}
</style>
