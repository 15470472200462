<script setup lang="ts">
import { TooltipProvider, type TooltipProviderProps } from "radix-vue";

const props = withDefaults(defineProps<TooltipProviderProps>(), { delayDuration: 300 });
</script>

<template>
	<TooltipProvider v-bind="props">
		<slot />
	</TooltipProvider>
</template>
