/* eslint-disable @typescript-eslint/ban-types,@typescript-eslint/no-unused-vars */
import { z } from "zod";
import { DateTimeSchema, IDSchema, JSONSchema, PermissionValueSchema } from "@/lib/schemas/scalars";
import type {
	AcidicValuesCreateInput,
	ActivityCreateInput,
	ActivityQueryFindManyWhereInput,
	ActivityQueryOrderByInput,
	AllActionsFindManyWhereInput,
	AllActionsQueryOrderByInput,
	BaseProductCreateOneInput,
	BaseProductUpdateDataOneInput,
	BaseProductUpdateOneInput,
	BaseProductWhereUniqueInput,
	CnmimBakersInput,
	CnmimMoldGrowthFormulaInput,
	CnmimProductCreateOneInput,
	CnmimProductIngredientInput,
	CnmimProductRegionAvailabilityDataInput,
	CnmimProductUpdateDataOneInput,
	CnmimProductUpdateOneInput,
	CnmimSodiumFormulaInput,
	CompanyReportFindManyWhereInput,
	CompanyReportQueryOrderByInput,
	ConnectRoleInput,
	ConnectToUniqueGroupInput,
	DateTimeFilter,
	DefaultunitofweightEnumFilter,
	DomainRuleCreateOneInput,
	DomainRuleFindManyWhereInput,
	DomainRuleFindUniqueInput,
	DomainRuleOrderByInput,
	DomainRuleType,
	DomainRuleUpdateDataOneInput,
	DomainRuleUpdateOneInput,
	GroupCreateOneInput,
	GroupFindManyWhereInput,
	GroupFindUniqueInput,
	GroupOrderByInput,
	GroupUpdateDataOneInput,
	GroupUpdateOneInput,
	IdFilter,
	IntFloatFilter,
	MoldCreateOneInput,
	MoldFindManyWhereInput,
	MoldGroup,
	MoldGroupFilter,
	MoldOrderByInput,
	MoldUpdateDataOneInput,
	MoldUpdateOneInput,
	MoldWhereUniqueInput,
	OrderByDirection,
	PermissionCreateOneInput,
	PortfolioFindManyWhereInput,
	PrivacyStatementCreateOneDataInput,
	PrivacyStatementFindManyWhereInput,
	PrivacyStatementOrderByInput,
	PrivacyStatementStatus,
	PrivacyStatementStatusFilter,
	PrivacyStatementUpdateOneDataInput,
	PrivacyStatementUpdateOneInput,
	PrivacyStatementWhereUniqueInput,
	ProductFindManyWhereInput,
	ProductOrderByInput,
	ProductType,
	ProductTypeFilter,
	RegionFindUniqueInput,
	RoleCreateOneInput,
	RoleFindManyWhereInput,
	RoleFindUniqueInput,
	RoleOrderByInput,
	RoleUpdateDataOneInput,
	RoleUpdateOneInput,
	ServiceHandle,
	StringFilter,
	TypeEnumFilter,
	UnitOfWeight,
	UpdateRolesRelationInput,
	UpdateSelfDataInput,
	UpdateSelfInput,
	UserFindManyWhereInput,
	UserFindUniqueInput,
	UserOrderByInput,
	UserReportFindManyWhereInput,
	UserReportQueryOrderByInput,
	UserSettingsUpdateInput,
	UserUpdateFindManyInput,
	UserUpdateManyDataInput,
	UserUpdateManyInput,
	UserUpdateOneDataInput,
	UserUpdateOneInput,
	WaterActivityCreateInput,
} from "./graphql";

type Properties<T> = Required<{
	[K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny =>
	v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export const DomainRuleTypeSchema = z.enum(["ALLOW", "BLOCK"]);

export const MoldGroupSchema = z.enum(["COMMON_FOOD", "PRESERVATIVE"]);

export const OrderByDirectionSchema = z.enum(["ASC", "DESC"]);

export const PrivacyStatementStatusSchema = z.enum(["DRAFT", "EXPIRED", "LIVE", "QUEUED"]);

export const ProductTypeSchema = z.enum([
	"ANTIMICROBIAL_PRODUCT",
	"CORBION_PRODUCT",
	"PROTOTYPE_PRODUCT",
	"PURE_PRODUCT",
]);

export const ServiceHandleSchema = z.enum(["ADMIN_PORTAL", "CNMIM"]);

export const UnitOfWeightSchema = z.enum(["GRAMS", "KILO_GRAMS", "OUNCES", "POUNDS"]);

export function AcidicValuesCreateInputSchema(): z.ZodObject<Properties<AcidicValuesCreateInput>> {
	return z.object({
		b: z.number(),
		lf: z.number(),
		mic: z.number(),
	});
}

export function ActivityCreateInputSchema(): z.ZodObject<Properties<ActivityCreateInput>> {
	return z.object({
		action: z.string().min(1),
		meta: JSONSchema.nullish(),
	});
}

export function ActivityQueryFindManyWhereInputSchema(): z.ZodObject<
	Properties<ActivityQueryFindManyWhereInput>
> {
	return z.object({
		action: StringFilterSchema().nullish(),
		actor: z.string().nullish(),
		timestamp: DateTimeFilterSchema().nullish(),
	});
}

export function ActivityQueryOrderByInputSchema(): z.ZodObject<
	Properties<ActivityQueryOrderByInput>
> {
	return z.object({
		timestamp: OrderByDirectionSchema.nullish(),
	});
}

export function AllActionsFindManyWhereInputSchema(): z.ZodObject<
	Properties<AllActionsFindManyWhereInput>
> {
	return z.object({
		action: StringFilterSchema().nullish(),
		timestamp: DateTimeFilterSchema().nullish(),
	});
}

export function AllActionsQueryOrderByInputSchema(): z.ZodObject<
	Properties<AllActionsQueryOrderByInput>
> {
	return z.object({
		sequence: OrderByDirectionSchema.nullish(),
		timestamp: OrderByDirectionSchema.nullish(),
	});
}

export function BaseProductCreateOneInputSchema(): z.ZodObject<
	Properties<BaseProductCreateOneInput>
> {
	return z.object({
		cnmim: z.lazy(() => CnmimProductCreateOneInputSchema().nullish()),
		name: z.string().min(1),
		sodiumPercentage: z.number(),
		type: ProductTypeSchema,
	});
}

export function BaseProductUpdateDataOneInputSchema(): z.ZodObject<
	Properties<BaseProductUpdateDataOneInput>
> {
	return z.object({
		cnmim: z.lazy(() => CnmimProductUpdateOneInputSchema().nullish()),
		name: z.string().nullish(),
		sodiumPercentage: z.number().nullish(),
		type: ProductTypeSchema.nullish(),
	});
}

export function BaseProductUpdateOneInputSchema(): z.ZodObject<
	Properties<BaseProductUpdateOneInput>
> {
	return z.object({
		data: z.lazy(() => BaseProductUpdateDataOneInputSchema()),
		where: z.lazy(() => BaseProductWhereUniqueInputSchema()),
	});
}

export function BaseProductWhereUniqueInputSchema(): z.ZodObject<
	Properties<BaseProductWhereUniqueInput>
> {
	return z.object({
		id: z.string().min(1),
	});
}

export function CnmimBakersInputSchema(): z.ZodObject<Properties<CnmimBakersInput>> {
	return z.object({
		bakeLoss: z.number(),
		flourWeight: z.number(),
		totalBatchWeight: z.number(),
	});
}

export function CnmimMoldGrowthFormulaInputSchema(): z.ZodObject<
	Properties<CnmimMoldGrowthFormulaInput>
> {
	return z.object({
		aW: z.number(),
		bakersInput: z.lazy(() => CnmimBakersInputSchema().nullish()),
		id: z.string().min(1),
		ingredients: z.array(z.lazy(() => CnmimProductIngredientInputSchema())),
		moisture: z.number(),
		name: z.string().min(1),
		pH: z.number(),
	});
}

export function CnmimProductCreateOneInputSchema(): z.ZodObject<
	Properties<CnmimProductCreateOneInput>
> {
	return z.object({
		AA: z.number(),
		PA: z.number(),
		SA: z.number(),
		availableRegions: z.array(z.lazy(() => CnmimProductRegionAvailabilityDataInputSchema())),
	});
}

export function CnmimProductIngredientInputSchema(): z.ZodObject<
	Properties<CnmimProductIngredientInput>
> {
	return z.object({
		id: IDSchema.min(1),
		weightForWeight: z.number(),
	});
}

export function CnmimProductRegionAvailabilityDataInputSchema(): z.ZodObject<
	Properties<CnmimProductRegionAvailabilityDataInput>
> {
	return z.object({
		available: z.boolean(),
		region: z.lazy(() => RegionFindUniqueInputSchema()),
	});
}

export function CnmimProductUpdateDataOneInputSchema(): z.ZodObject<
	Properties<CnmimProductUpdateDataOneInput>
> {
	return z.object({
		AA: z.number().nullish(),
		PA: z.number().nullish(),
		SA: z.number().nullish(),
		availableRegions: z
			.array(z.lazy(() => CnmimProductRegionAvailabilityDataInputSchema()))
			.nullish(),
	});
}

export function CnmimProductUpdateOneInputSchema(): z.ZodObject<
	Properties<CnmimProductUpdateOneInput>
> {
	return z.object({
		create: z.lazy(() => CnmimProductCreateOneInputSchema().nullish()),
		delete: z.boolean().nullish(),
		update: z.lazy(() => CnmimProductUpdateDataOneInputSchema().nullish()),
	});
}

export function CnmimSodiumFormulaInputSchema(): z.ZodObject<Properties<CnmimSodiumFormulaInput>> {
	return z.object({
		bakersInput: z.lazy(() => CnmimBakersInputSchema()),
		id: z.string().min(1),
		ingredients: z.array(z.lazy(() => CnmimProductIngredientInputSchema())),
		name: z.string().min(1),
		servingSizeInGrams: z.number(),
	});
}

export function CompanyReportFindManyWhereInputSchema(): z.ZodObject<
	Properties<CompanyReportFindManyWhereInput>
> {
	return z.object({
		accountEnabled: z.boolean().nullish(),
		timestamp: DateTimeFilterSchema().nullish(),
	});
}

export function CompanyReportQueryOrderByInputSchema(): z.ZodObject<
	Properties<CompanyReportQueryOrderByInput>
> {
	return z.object({
		lastLoggedIn: OrderByDirectionSchema.nullish(),
	});
}

export function ConnectRoleInputSchema(): z.ZodObject<Properties<ConnectRoleInput>> {
	return z.object({
		handle: z.string().nullish(),
		id: z.string().nullish(),
	});
}

export function ConnectToUniqueGroupInputSchema(): z.ZodObject<
	Properties<ConnectToUniqueGroupInput>
> {
	return z.object({
		handle: z.string().nullish(),
		id: IDSchema.nullish(),
	});
}

export function DateTimeFilterSchema(): z.ZodObject<Properties<DateTimeFilter>> {
	return z.object({
		equals: DateTimeSchema.nullish(),
		gt: DateTimeSchema.nullish(),
		gte: DateTimeSchema.nullish(),
		in: z.array(DateTimeSchema.min(1)).nullish(),
		lt: DateTimeSchema.nullish(),
		lte: DateTimeSchema.nullish(),
	});
}

export function DefaultunitofweightEnumFilterSchema(): z.ZodObject<
	Properties<DefaultunitofweightEnumFilter>
> {
	return z.object({
		equals: UnitOfWeightSchema.nullish(),
		in: z.array(UnitOfWeightSchema).nullish(),
		not: UnitOfWeightSchema.nullish(),
		notIn: z.array(UnitOfWeightSchema).nullish(),
	});
}

export function DomainRuleCreateOneInputSchema(): z.ZodObject<
	Properties<DomainRuleCreateOneInput>
> {
	return z.object({
		defaultGroup: z.lazy(() => ConnectToUniqueGroupInputSchema().nullish()),
		domain: z.string().min(1),
		reason: z.string().nullish(),
		type: DomainRuleTypeSchema,
	});
}

export function DomainRuleFindManyWhereInputSchema(): z.ZodObject<
	Properties<DomainRuleFindManyWhereInput>
> {
	return z.object({
		createdAt: DateTimeFilterSchema().nullish(),
		domain: StringFilterSchema().nullish(),
		id: IdFilterSchema().nullish(),
		reason: StringFilterSchema().nullish(),
		type: TypeEnumFilterSchema().nullish(),
		updatedAt: DateTimeFilterSchema().nullish(),
	});
}

export function DomainRuleFindUniqueInputSchema(): z.ZodObject<
	Properties<DomainRuleFindUniqueInput>
> {
	return z.object({
		domain: z.string().nullish(),
		id: IDSchema.nullish(),
	});
}

export function DomainRuleOrderByInputSchema(): z.ZodObject<Properties<DomainRuleOrderByInput>> {
	return z.object({
		createdAt: OrderByDirectionSchema.nullish(),
		domain: OrderByDirectionSchema.nullish(),
		id: OrderByDirectionSchema.nullish(),
		reason: OrderByDirectionSchema.nullish(),
		type: OrderByDirectionSchema.nullish(),
		updatedAt: OrderByDirectionSchema.nullish(),
	});
}

export function DomainRuleUpdateDataOneInputSchema(): z.ZodObject<
	Properties<DomainRuleUpdateDataOneInput>
> {
	return z.object({
		defaultGroup: z.lazy(() => ConnectToUniqueGroupInputSchema().nullish()),
		domain: z.string().nullish(),
		reason: z.string().nullish(),
		type: DomainRuleTypeSchema,
	});
}

export function DomainRuleUpdateOneInputSchema(): z.ZodObject<
	Properties<DomainRuleUpdateOneInput>
> {
	return z.object({
		data: z.lazy(() => DomainRuleUpdateDataOneInputSchema()),
		where: z.lazy(() => DomainRuleFindUniqueInputSchema()),
	});
}

export function GroupCreateOneInputSchema(): z.ZodObject<Properties<GroupCreateOneInput>> {
	return z.object({
		description: z.string().min(1),
		handle: z.string().min(1),
		price: z.number(),
		roles: z.array(z.string().min(1)),
		title: z.string().min(1),
	});
}

export function GroupFindManyWhereInputSchema(): z.ZodObject<Properties<GroupFindManyWhereInput>> {
	return z.object({
		createdAt: DateTimeFilterSchema().nullish(),
		description: StringFilterSchema().nullish(),
		handle: StringFilterSchema().nullish(),
		id: IdFilterSchema().nullish(),
		price: IntFloatFilterSchema().nullish(),
		title: StringFilterSchema().nullish(),
		updatedAt: DateTimeFilterSchema().nullish(),
	});
}

export function GroupFindUniqueInputSchema(): z.ZodObject<Properties<GroupFindUniqueInput>> {
	return z.object({
		handle: z.string().nullish(),
		id: IDSchema.nullish(),
	});
}

export function GroupOrderByInputSchema(): z.ZodObject<Properties<GroupOrderByInput>> {
	return z.object({
		createdAt: OrderByDirectionSchema.nullish(),
		description: OrderByDirectionSchema.nullish(),
		handle: OrderByDirectionSchema.nullish(),
		id: OrderByDirectionSchema.nullish(),
		price: OrderByDirectionSchema.nullish(),
		title: OrderByDirectionSchema.nullish(),
		updatedAt: OrderByDirectionSchema.nullish(),
	});
}

export function GroupUpdateDataOneInputSchema(): z.ZodObject<Properties<GroupUpdateDataOneInput>> {
	return z.object({
		description: z.string().nullish(),
		handle: z.string().nullish(),
		price: z.number().nullish(),
		roles: z.array(z.string().min(1)).nullish(),
		title: z.string().nullish(),
	});
}

export function GroupUpdateOneInputSchema(): z.ZodObject<Properties<GroupUpdateOneInput>> {
	return z.object({
		data: z.lazy(() => GroupUpdateDataOneInputSchema()),
		where: z.lazy(() => GroupFindUniqueInputSchema()),
	});
}

export function IdFilterSchema(): z.ZodObject<Properties<IdFilter>> {
	return z.object({
		equals: IDSchema.nullish(),
		in: IDSchema.nullish(),
		notIn: IDSchema.nullish(),
	});
}

export function IntFloatFilterSchema(): z.ZodObject<Properties<IntFloatFilter>> {
	return z.object({
		equals: z.number().nullish(),
		gt: z.number().nullish(),
		gte: z.number().nullish(),
		in: z.array(z.number()).nullish(),
		lt: z.number().nullish(),
		lte: z.number().nullish(),
	});
}

export function MoldCreateOneInputSchema(): z.ZodObject<Properties<MoldCreateOneInput>> {
	return z.object({
		AA: z.lazy(() => AcidicValuesCreateInputSchema()),
		CSNumber: IDSchema.min(1),
		PA: z.lazy(() => AcidicValuesCreateInputSchema()),
		SA: z.lazy(() => AcidicValuesCreateInputSchema()),
		aW: z.lazy(() => WaterActivityCreateInputSchema()),
		isolatedFrom: z.string().min(1),
		moldGroup: MoldGroupSchema,
		name: z.string().min(1),
		strainCode: z.string().min(1),
	});
}

export function MoldFindManyWhereInputSchema(): z.ZodObject<Properties<MoldFindManyWhereInput>> {
	return z.object({
		CSNumber: IdFilterSchema().nullish(),
		createdAt: DateTimeFilterSchema().nullish(),
		id: IdFilterSchema().nullish(),
		isolatedFrom: StringFilterSchema().nullish(),
		moldGroup: MoldGroupFilterSchema().nullish(),
		name: StringFilterSchema().nullish(),
		strainCode: StringFilterSchema().nullish(),
		updatedAt: DateTimeFilterSchema().nullish(),
	});
}

export function MoldGroupFilterSchema(): z.ZodObject<Properties<MoldGroupFilter>> {
	return z.object({
		equals: MoldGroupSchema.nullish(),
		in: z.array(MoldGroupSchema).nullish(),
		not: MoldGroupSchema.nullish(),
		notIn: z.array(MoldGroupSchema).nullish(),
	});
}

export function MoldOrderByInputSchema(): z.ZodObject<Properties<MoldOrderByInput>> {
	return z.object({
		CSNumber: OrderByDirectionSchema.nullish(),
		createdAt: OrderByDirectionSchema.nullish(),
		moldGroup: OrderByDirectionSchema.nullish(),
		name: OrderByDirectionSchema.nullish(),
		strainCode: OrderByDirectionSchema.nullish(),
		updatedAt: OrderByDirectionSchema.nullish(),
	});
}

export function MoldUpdateDataOneInputSchema(): z.ZodObject<Properties<MoldUpdateDataOneInput>> {
	return z.object({
		AA: z.lazy(() => AcidicValuesCreateInputSchema().nullish()),
		CSNumber: IDSchema.nullish(),
		PA: z.lazy(() => AcidicValuesCreateInputSchema().nullish()),
		SA: z.lazy(() => AcidicValuesCreateInputSchema().nullish()),
		aW: z.lazy(() => WaterActivityCreateInputSchema().nullish()),
		isolatedFrom: z.string().nullish(),
		moldGroup: MoldGroupSchema.nullish(),
		name: z.string().nullish(),
		strainCode: z.string().nullish(),
	});
}

export function MoldUpdateOneInputSchema(): z.ZodObject<Properties<MoldUpdateOneInput>> {
	return z.object({
		data: z.lazy(() => MoldUpdateDataOneInputSchema()),
		where: z.lazy(() => MoldWhereUniqueInputSchema()),
	});
}

export function MoldWhereUniqueInputSchema(): z.ZodObject<Properties<MoldWhereUniqueInput>> {
	return z.object({
		CSNumber: z.string().nullish(),
		id: IDSchema.nullish(),
	});
}

export function PermissionCreateOneInputSchema(): z.ZodObject<
	Properties<PermissionCreateOneInput>
> {
	return z.object({
		handle: z.string().min(1),
		value: PermissionValueSchema,
	});
}

export function PortfolioFindManyWhereInputSchema(): z.ZodObject<
	Properties<PortfolioFindManyWhereInput>
> {
	return z.object({
		regionAvailable: z.lazy(() => RegionFindUniqueInputSchema()),
	});
}

export function PrivacyStatementCreateOneDataInputSchema(): z.ZodObject<
	Properties<PrivacyStatementCreateOneDataInput>
> {
	return z.object({
		content: z.string().min(1),
		publishedAt: DateTimeSchema.nullish(),
		service: ServiceHandleSchema,
	});
}

export function PrivacyStatementFindManyWhereInputSchema(): z.ZodObject<
	Properties<PrivacyStatementFindManyWhereInput>
> {
	return z.object({
		content: StringFilterSchema().nullish(),
		createdAt: DateTimeFilterSchema().nullish(),
		id: IdFilterSchema().nullish(),
		publishedAt: DateTimeFilterSchema().nullish(),
		status: PrivacyStatementStatusFilterSchema().nullish(),
		updatedAt: DateTimeFilterSchema().nullish(),
		version: IntFloatFilterSchema().nullish(),
	});
}

export function PrivacyStatementOrderByInputSchema(): z.ZodObject<
	Properties<PrivacyStatementOrderByInput>
> {
	return z.object({
		content: OrderByDirectionSchema.nullish(),
		createdAt: OrderByDirectionSchema.nullish(),
		id: OrderByDirectionSchema.nullish(),
		publishedAt: OrderByDirectionSchema.nullish(),
		updatedAt: OrderByDirectionSchema.nullish(),
		version: OrderByDirectionSchema.nullish(),
	});
}

export function PrivacyStatementStatusFilterSchema(): z.ZodObject<
	Properties<PrivacyStatementStatusFilter>
> {
	return z.object({
		equals: PrivacyStatementStatusSchema.nullish(),
		in: z.array(PrivacyStatementStatusSchema).nullish(),
		not: PrivacyStatementStatusSchema.nullish(),
		notIn: z.array(PrivacyStatementStatusSchema).nullish(),
	});
}

export function PrivacyStatementUpdateOneDataInputSchema(): z.ZodObject<
	Properties<PrivacyStatementUpdateOneDataInput>
> {
	return z.object({
		content: z.string().nullish(),
		publishedAt: DateTimeSchema.nullish(),
	});
}

export function PrivacyStatementUpdateOneInputSchema(): z.ZodObject<
	Properties<PrivacyStatementUpdateOneInput>
> {
	return z.object({
		data: z.lazy(() => PrivacyStatementUpdateOneDataInputSchema()),
		where: z.lazy(() => PrivacyStatementWhereUniqueInputSchema()),
	});
}

export function PrivacyStatementWhereUniqueInputSchema(): z.ZodObject<
	Properties<PrivacyStatementWhereUniqueInput>
> {
	return z.object({
		id: z.string().min(1),
	});
}

export function ProductFindManyWhereInputSchema(): z.ZodObject<
	Properties<ProductFindManyWhereInput>
> {
	return z.object({
		archived: z.boolean().nullish(),
		name: StringFilterSchema().nullish(),
		type: ProductTypeFilterSchema().nullish(),
	});
}

export function ProductOrderByInputSchema(): z.ZodObject<Properties<ProductOrderByInput>> {
	return z.object({
		createdAt: OrderByDirectionSchema.nullish(),
		name: OrderByDirectionSchema.nullish(),
		type: OrderByDirectionSchema.nullish(),
		updatedAt: OrderByDirectionSchema.nullish(),
	});
}

export function ProductTypeFilterSchema(): z.ZodObject<Properties<ProductTypeFilter>> {
	return z.object({
		equals: ProductTypeSchema.nullish(),
		in: z.array(ProductTypeSchema).nullish(),
		not: ProductTypeSchema.nullish(),
		notIn: z.array(ProductTypeSchema).nullish(),
	});
}

export function RegionFindUniqueInputSchema(): z.ZodObject<Properties<RegionFindUniqueInput>> {
	return z.object({
		handle: z.string().nullish(),
		id: IDSchema.nullish(),
	});
}

export function RoleCreateOneInputSchema(): z.ZodObject<Properties<RoleCreateOneInput>> {
	return z.object({
		handle: z.string().min(1),
		permissions: z.array(z.lazy(() => PermissionCreateOneInputSchema())),
		title: z.string().min(1),
	});
}

export function RoleFindManyWhereInputSchema(): z.ZodObject<Properties<RoleFindManyWhereInput>> {
	return z.object({
		createdAt: DateTimeFilterSchema().nullish(),
		handle: StringFilterSchema().nullish(),
		id: IdFilterSchema().nullish(),
		title: StringFilterSchema().nullish(),
		updatedAt: DateTimeFilterSchema().nullish(),
	});
}

export function RoleFindUniqueInputSchema(): z.ZodObject<Properties<RoleFindUniqueInput>> {
	return z.object({
		handle: z.string().nullish(),
		id: IDSchema.nullish(),
	});
}

export function RoleOrderByInputSchema(): z.ZodObject<Properties<RoleOrderByInput>> {
	return z.object({
		createdAt: OrderByDirectionSchema.nullish(),
		handle: OrderByDirectionSchema.nullish(),
		id: OrderByDirectionSchema.nullish(),
		title: OrderByDirectionSchema.nullish(),
		updatedAt: OrderByDirectionSchema.nullish(),
	});
}

export function RoleUpdateDataOneInputSchema(): z.ZodObject<Properties<RoleUpdateDataOneInput>> {
	return z.object({
		handle: z.string().nullish(),
		permissions: z.array(z.lazy(() => PermissionCreateOneInputSchema())).nullish(),
		title: z.string().nullish(),
	});
}

export function RoleUpdateOneInputSchema(): z.ZodObject<Properties<RoleUpdateOneInput>> {
	return z.object({
		data: z.lazy(() => RoleUpdateDataOneInputSchema()),
		where: z.lazy(() => RoleFindUniqueInputSchema()),
	});
}

export function StringFilterSchema(): z.ZodObject<Properties<StringFilter>> {
	return z.object({
		contains: z.string().nullish(),
		equals: z.string().nullish(),
		in: z.array(z.string().min(1)).nullish(),
		notIn: z.array(z.string().min(1)).nullish(),
	});
}

export function TypeEnumFilterSchema(): z.ZodObject<Properties<TypeEnumFilter>> {
	return z.object({
		equals: DomainRuleTypeSchema.nullish(),
		in: z.array(DomainRuleTypeSchema).nullish(),
		not: DomainRuleTypeSchema.nullish(),
		notIn: z.array(DomainRuleTypeSchema).nullish(),
	});
}

export function UpdateRolesRelationInputSchema(): z.ZodObject<
	Properties<UpdateRolesRelationInput>
> {
	return z.object({
		connect: z.array(z.lazy(() => ConnectRoleInputSchema())).nullish(),
		disconnect: z.array(z.lazy(() => ConnectRoleInputSchema())).nullish(),
	});
}

export function UpdateSelfDataInputSchema(): z.ZodObject<Properties<UpdateSelfDataInput>> {
	return z.object({
		companyName: z.string().nullish(),
		companyNumberOfEmployees: z.string().nullish(),
		companyType: z.string().nullish(),
		country: z.string().nullish(),
		defaultUnitOfWeight: UnitOfWeightSchema.nullish(),
		givenName: z.string().nullish(),
		jobTitle: z.string().nullish(),
		newsletter: z.boolean().nullish(),
		settings: z.lazy(() => UserSettingsUpdateInputSchema().nullish()),
		surname: z.string().nullish(),
	});
}

export function UpdateSelfInputSchema(): z.ZodObject<Properties<UpdateSelfInput>> {
	return z.object({
		data: z.lazy(() => UpdateSelfDataInputSchema()),
	});
}

export function UserFindManyWhereInputSchema(): z.ZodObject<Properties<UserFindManyWhereInput>> {
	return z.object({
		accountEnabled: z.boolean().nullish(),
		companyName: StringFilterSchema().nullish(),
		companyNumberOfEmployees: StringFilterSchema().nullish(),
		companyType: StringFilterSchema().nullish(),
		country: StringFilterSchema().nullish(),
		createdAt: DateTimeFilterSchema().nullish(),
		defaultUnitOfWeight: DefaultunitofweightEnumFilterSchema().nullish(),
		email: StringFilterSchema().nullish(),
		givenName: StringFilterSchema().nullish(),
		id: IdFilterSchema().nullish(),
		jobTitle: StringFilterSchema().nullish(),
		newsletter: z.boolean().nullish(),
		surname: StringFilterSchema().nullish(),
		updatedAt: DateTimeFilterSchema().nullish(),
	});
}

export function UserFindUniqueInputSchema(): z.ZodObject<Properties<UserFindUniqueInput>> {
	return z.object({
		email: z.string().nullish(),
		id: z.string().nullish(),
	});
}

export function UserOrderByInputSchema(): z.ZodObject<Properties<UserOrderByInput>> {
	return z.object({
		accountEnabled: OrderByDirectionSchema.nullish(),
		companyName: OrderByDirectionSchema.nullish(),
		companyNumberOfEmployees: OrderByDirectionSchema.nullish(),
		companyType: OrderByDirectionSchema.nullish(),
		country: OrderByDirectionSchema.nullish(),
		createdAt: OrderByDirectionSchema.nullish(),
		defaultUnitOfWeight: OrderByDirectionSchema.nullish(),
		email: OrderByDirectionSchema.nullish(),
		givenName: OrderByDirectionSchema.nullish(),
		id: OrderByDirectionSchema.nullish(),
		jobTitle: OrderByDirectionSchema.nullish(),
		newsletter: OrderByDirectionSchema.nullish(),
		surname: OrderByDirectionSchema.nullish(),
		updatedAt: OrderByDirectionSchema.nullish(),
	});
}

export function UserReportFindManyWhereInputSchema(): z.ZodObject<
	Properties<UserReportFindManyWhereInput>
> {
	return z.object({
		accountEnabled: z.boolean().nullish(),
		timestamp: DateTimeFilterSchema().nullish(),
	});
}

export function UserReportQueryOrderByInputSchema(): z.ZodObject<
	Properties<UserReportQueryOrderByInput>
> {
	return z.object({
		lastLoggedIn: OrderByDirectionSchema.nullish(),
		surname: OrderByDirectionSchema.nullish(),
	});
}

export function UserSettingsUpdateInputSchema(): z.ZodObject<Properties<UserSettingsUpdateInput>> {
	return z.object({
		cnmimSettings: JSONSchema.nullish(),
		defaultUnitOfWeight: UnitOfWeightSchema.nullish(),
		paginationLimit: z.number().nullish(),
		servingSizeInGrams: z.number().nullish(),
	});
}

export function UserUpdateFindManyInputSchema(): z.ZodObject<Properties<UserUpdateFindManyInput>> {
	return z.object({
		emails: z.array(z.string().min(1)).nullish(),
		ids: z.array(z.string().min(1)).nullish(),
	});
}

export function UserUpdateManyDataInputSchema(): z.ZodObject<Properties<UserUpdateManyDataInput>> {
	return z.object({
		accountEnabled: z.boolean().nullish(),
		companyName: z.string().nullish(),
		companyNumberOfEmployees: z.string().nullish(),
		companyType: z.string().nullish(),
		country: z.string().nullish(),
		group: z.string().nullish(),
		newsletter: z.boolean().nullish(),
		roles: z.lazy(() => UpdateRolesRelationInputSchema().nullish()),
	});
}

export function UserUpdateManyInputSchema(): z.ZodObject<Properties<UserUpdateManyInput>> {
	return z.object({
		data: z.lazy(() => UserUpdateManyDataInputSchema()),
		where: z.lazy(() => UserUpdateFindManyInputSchema()),
	});
}

export function UserUpdateOneDataInputSchema(): z.ZodObject<Properties<UserUpdateOneDataInput>> {
	return z.object({
		accountEnabled: z.boolean().nullish(),
		companyName: z.string().nullish(),
		companyNumberOfEmployees: z.string().nullish(),
		companyType: z.string().nullish(),
		country: z.string().nullish(),
		defaultUnitOfWeight: UnitOfWeightSchema.nullish(),
		givenName: z.string().nullish(),
		group: z.string().nullish(),
		jobTitle: z.string().nullish(),
		newsletter: z.boolean().nullish(),
		roles: z.array(z.string().min(1)).nullish(),
		settings: z.lazy(() => UserSettingsUpdateInputSchema().nullish()),
		surname: z.string().nullish(),
	});
}

export function UserUpdateOneInputSchema(): z.ZodObject<Properties<UserUpdateOneInput>> {
	return z.object({
		data: z.lazy(() => UserUpdateOneDataInputSchema()),
		where: z.lazy(() => UserFindUniqueInputSchema()),
	});
}

export function WaterActivityCreateInputSchema(): z.ZodObject<
	Properties<WaterActivityCreateInput>
> {
	return z.object({
		a: z.number(),
		b: z.number(),
		lf: z.number(),
	});
}
