<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { cx } from "@/lib/style/cva.config";

const props = defineProps<{
	class?: HTMLAttributes["class"];
}>();
</script>

<template>
	<div :class="cx('flex flex-col gap-y-1.5 text-center sm:text-left', props.class)">
		<slot />
	</div>
</template>
