import { computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { useIsAuthenticated } from "@stijlbreuk/msal-vue";
import { defineStore, storeToRefs } from "pinia";
import { graphql } from "@/graphql";
import { useConfigurationStore } from "@/store/configuration";

export const useProductsStore = defineStore("products", () => {
	const isAuthenticated = useIsAuthenticated();
	const { region } = storeToRefs(useConfigurationStore());

	const portfolioQuery = useQuery(
		graphql(`
			query PortfolioProducts($regionHandle: String!) {
				CNMIMPortfolio(input: { regionAvailable: { handle: $regionHandle } }) {
					id
					name
				}
			}
		`),
		{ regionHandle: region.value! },
		{
			fetchPolicy: "cache-and-network",
			notifyOnNetworkStatusChange: true,
			enabled: isAuthenticated.value && !!region.value,
		},
	);

	const products = computed(() => portfolioQuery.result.value?.CNMIMPortfolio ?? []);

	return { products };
});
