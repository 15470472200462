// Based on https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/src/hooks/useMsal.ts
import { getCurrentInstance, toRefs } from "vue";
import type { IMsalContext } from "../types/IMsalContext";
import { getIMsalContext } from "../utils/utilities";

export function useMsal(): IMsalContext {
	const internalInstance = getCurrentInstance();
	if (!internalInstance) {
		throw "useMsal() cannot be called outside the setup() function of a component";
	}

	const { instance, accounts, inProgress, logger } = toRefs(
		getIMsalContext(internalInstance.appContext.config.globalProperties.$msal),
	);

	if (!instance.value || !accounts.value || !inProgress.value || !logger.value) {
		throw "Please install the msalVuePlugin";
	}

	return {
		instance: instance.value,
		accounts,
		inProgress,
		logger,
	};
}
