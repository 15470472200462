<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { ChevronDown } from "lucide-vue-next";
import {
	SelectScrollDownButton,
	type SelectScrollDownButtonProps,
	useForwardProps,
} from "radix-vue";
import { cx } from "@/lib/style/cva.config";

const props = defineProps<SelectScrollDownButtonProps & { class?: HTMLAttributes["class"] }>();

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
	<SelectScrollDownButton
		v-bind="forwardedProps"
		:class="cx('flex cursor-default items-center justify-center py-1', props.class)"
	>
		<slot>
			<ChevronDown class="size-4" />
		</slot>
	</SelectScrollDownButton>
</template>
