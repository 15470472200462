import { type RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import { useConfigurationStore } from "@/store/configuration";
import { registerGuard } from "./Guard";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Authentication",
		component: () => import("@/views/Authentication.vue"),
		meta: {
			requiresUnauth: true,
		},
	},
	{
		path: "/configure",
		name: "Configure",
		redirect: { name: "Configure region" },
		children: [
			{
				name: "Configure region",
				path: "region",
				alias: ["regions"],
				component: () => import("@/views/Configure/Region.vue"),
				meta: {
					requiresAuth: true,
					showFixedActionbar: true,
				},
			},
			{
				name: "Configure product",
				path: "product",
				alias: ["products"],
				component: () => import("@/views/Configure/Product.vue"),
				meta: {
					requiresAuth: true,
					showFixedActionbar: true,
				},
				beforeEnter: () => {
					const { region } = useConfigurationStore();

					if (!region) {
						return { name: "Configure region" };
					}

					return true;
				},
			},
		],
	},
	{
		path: "/calculate",
		name: "Calculate",
		component: () => import("@/views/Calculate/Calculate.vue"),
		meta: {
			requiresAuth: true,
			showFixedActionbar: true,
		},
		beforeEnter: (_to, _from, next) => {
			const { region, foodType } = useConfigurationStore();

			if (!region) {
				return next({ name: "Configure region" });
			}

			if (!foodType) {
				return next({ name: "Configure product" });
			}

			next();
		},
	},
	{
		path: "/result/:id",
		name: "Result",
		component: () => import("@/views/Result.vue"),
		meta: {
			requiresAuth: true,
			hideHeader: true,
			hideFooter: true,
		},
	},
	{
		path: "/faq",
		name: "FAQ",
		component: () => import("@/views/FAQ.vue"),
	},
	{
		path: "/disclaimer",
		name: "Disclaimer",
		component: () => import("@/views/Disclaimer.vue"),
	},
	{
		path: "/privacy-statement",
		name: "Privacy Statement",
		component: () => import("@/views/PrivacyStatement.vue"),
	},
	{
		path: "/invite",
		name: "Invite",
		component: () => import("@/views/Invite.vue"),
	},
	{
		path: "/redirect/sign-in",
		name: "Sign in redirect",
		component: () => import("@/views/RedirectSignIn.vue"),
	},
	{ path: "/privacy-policy", redirect: "/privacy-statement" },
	{ path: "/privacy", redirect: "/privacy-statement" },
	{ path: "/terms-of-use", redirect: "/disclaimer" },
	{ path: "/terms", redirect: "/disclaimer" },
	{ path: "/:pathMatch(.*)*", redirect: "/" },
];

export const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (
			["Disclaimer", "Privacy Statement"].includes(String(to.name)) ||
			!(to.name === "FAQ" && from.name === "FAQ")
		) {
			return { top: 0 };
		}

		if (savedPosition) {
			return savedPosition;
		}
	},
});

registerGuard(router);
