import { computed, ref } from "vue";
import { cloneDeep } from "lodash";
import { defineStore, storeToRefs } from "pinia";
import type { CnmimProductIngredientInput } from "@/graphql/graphql";
import { DEFAULT_CONTROL_DAYS } from "@/lib/constants/app";
import type { Formulation, YourReferenceFormulation } from "@/types/calculation";
import { useMiscStore } from "./misc";

export const REFERENCE_FORMULATION_ID = "your_reference";

export const TEMPORARY_INGREDIENT_ID_PREFIX = "temporary-";

export const DEFAULT_REFERENCE_FORMULATION = {
	id: REFERENCE_FORMULATION_ID,
	name: "Your reference",
	bakersInput: {
		flourWeight: 1200,
		totalBatchWeight: 2250,
		bakeLoss: 13,
	},
	daysToVisualMoldGrowth: DEFAULT_CONTROL_DAYS,
	moisture: undefined,
	pH: undefined,
	aW: undefined,
	ingredients: [],
};

export const DEFAULT_FIRST_FORMULATION = {
	id: crypto.randomUUID(),
	idName: "1",
	name: "Formulation 1",
	bakersInput: {
		flourWeight: 1200,
		totalBatchWeight: 2250,
		bakeLoss: 13,
	},
	daysToVisualMoldGrowth: DEFAULT_CONTROL_DAYS,
	moisture: undefined,
	pH: undefined,
	aW: undefined,
	ingredients: [],
};

export const useFormulationsStore = defineStore(
	"formulation",
	() => {
		const formulations = ref<Formulation[]>([
			cloneDeep(DEFAULT_REFERENCE_FORMULATION),
			cloneDeep(DEFAULT_FIRST_FORMULATION),
		]);
		const selected = ref<string>();
		const miscStore = useMiscStore();
		const { compactMode } = storeToRefs(miscStore);

		const referenceFormulation = computed(
			() =>
				formulations.value.find(
					({ id }) => id === REFERENCE_FORMULATION_ID,
				) as YourReferenceFormulation,
		);
		const activeFormulation = computed(
			() => formulations.value.find(({ id }) => id === selected.value) ?? formulations.value[0],
		);

		function findProductInFormulation(formulation: Formulation, productId?: string) {
			return formulation.ingredients.find((ing) => ing.id === productId);
		}

		/** Add spot to ingredients */
		function addEmptySpotToIngredientsRegularMode() {
			activeFormulation.value.ingredients.push({
				id: `${TEMPORARY_INGREDIENT_ID_PREFIX}${crypto.randomUUID()}`,
				weightForWeight: 0,
			});
		}

		function addEmptySpotToIngredientsCompactMode() {
			const id = `${TEMPORARY_INGREDIENT_ID_PREFIX}${crypto.randomUUID()}`;

			if (formulations.value.length === 1) {
				addEmptySpotToIngredientsRegularMode();
			} else {
				formulations.value.forEach((formulation) => {
					formulation.ingredients.push({
						id,
						weightForWeight: null as any,
					});
				});
			}
		}

		function addEmptySpot() {
			if (compactMode.value) {
				addEmptySpotToIngredientsCompactMode();
			} else {
				addEmptySpotToIngredientsRegularMode();
			}
		}

		/** Remove spot from ingredients */
		function removeSpotFromIngredientsRegularMode(productId: string) {
			activeFormulation.value.ingredients = activeFormulation.value.ingredients.filter(
				(ing) => ing.id !== productId,
			);
		}

		function removeSpotFromIngredientsCompactMode(productId: string) {
			formulations.value.forEach((formulation) => {
				formulation.ingredients = formulation.ingredients.filter((ing) => ing.id !== productId);
			});
		}

		function removeSpot(productId: string) {
			if (compactMode.value) {
				removeSpotFromIngredientsCompactMode(productId);
			} else {
				removeSpotFromIngredientsRegularMode(productId);
			}
		}

		function updateIngredientRegularMode(oldProductId: string, newProductId: string) {
			activeFormulation.value.ingredients.forEach((ingredient) => {
				if (ingredient.id === oldProductId) {
					ingredient.id = newProductId;
				}
			});
		}

		function updateIngredientCompactMode(oldProductId: string, newProductId: string) {
			formulations.value.forEach((formulation) => {
				const product = formulation.ingredients.find((ing) => ing.id === oldProductId);
				if (product) {
					product.id = newProductId;
				}
			});
		}

		function updateIngredient(oldProductId: string, newProductId: string) {
			if (compactMode.value) {
				updateIngredientCompactMode(oldProductId, newProductId);
			} else {
				updateIngredientRegularMode(oldProductId, newProductId);
			}
		}

		function updateIngredientWeight(
			formulation: Formulation,
			productId: string,
			newValue: number | string | null,
		) {
			if (!compactMode.value && newValue === null) {
				// Don't update to null because it will remove the input field visible in regular mode.

				const foundProduct = findProductInFormulation(formulation, productId);
				if (foundProduct) {
					foundProduct.weightForWeight = 0;
				}
				return;
			}

			const value = typeof newValue === "string" ? Number(newValue) : newValue;

			const foundProduct = findProductInFormulation(formulation, productId);
			if (foundProduct) {
				foundProduct.weightForWeight = value as any; // Should be allowed to set null.
			}
		}

		/**
		 * Find all unique Corbion products over all formulations. For each formulation add the missing
		 * products from other formulations with "null" value.
		 */
		function syncProducts() {
			// Purge all undefined weights. - Used for temporary input fields.
			formulations.value.forEach((formulation) => {
				formulation.ingredients = formulation.ingredients.filter(
					(ingredient) => ingredient.weightForWeight !== undefined,
				);
			});

			const allProducts = formulations.value
				.flatMap((formulation) => formulation.ingredients)
				.reduce((acc, curr) => {
					if (!acc.some((ingredient) => ingredient.id === curr.id)) {
						acc.push(curr);
					}
					return acc;
				}, [] as CnmimProductIngredientInput[]);

			formulations.value.forEach((formulation) => {
				allProducts.forEach((product) => {
					const foundProduct = findProductInFormulation(formulation, product.id);
					if (!foundProduct) {
						formulation.ingredients.push({ id: product.id, weightForWeight: null as any });
					}
				});
			});
		}

		const ingredientLimitExceededForSomeFormulation = computed(() =>
			formulations.value.some(
				(formulation) =>
					formulation.ingredients.filter((ing) => ing.weightForWeight !== null).length >= 3,
			),
		);

		const allUsedIngredients = computed(() =>
			formulations.value
				.flatMap((formulation) => formulation.ingredients)
				.reduce((acc, curr) => {
					if (!acc.some((ingredient) => ingredient.id === curr.id)) {
						acc.push(curr);
					}
					return acc;
				}, [] as CnmimProductIngredientInput[]),
		);

		/**
		 * Formulations to be used in the calculation. Calculated by using "formulations" and removing
		 * all ingredients with weightForWeight === null.
		 */
		const calculationFormulations = computed(() => {
			return formulations.value.map((formulation) => {
				return {
					...formulation,
					ingredients: formulation.ingredients.filter(
						(ingredient) =>
							!ingredient.id.startsWith(TEMPORARY_INGREDIENT_ID_PREFIX) &&
							ingredient.weightForWeight !== null &&
							ingredient.weightForWeight !== 0,
					),
				};
			});
		});

		// Sync products on load
		syncProducts();

		return {
			selected,
			formulations,
			calculationFormulations,
			referenceFormulation,
			activeFormulation,
			ingredientLimitExceededForSomeFormulation,
			allUsedIngredients,

			addEmptySpot,
			removeSpot,
			updateIngredient,
			updateIngredientWeight,
			syncProducts,
		};
	},
	{
		persist: {
			paths: ["formulations", "selected"],
			serializer: {
				deserialize: (value) => JSON.parse(value) as any,
				// deserialize: (value) => FormulationsSchema.parse(JSON.parse(value)),
				serialize: (value) => JSON.stringify(value),
			},
		},
	},
);
