import { graphql } from "@/graphql";
import { apolloClient } from "@/lib/services/apollo/client";

export enum AnalyticsAction {
	PrintPdf = "formulation.print_pdf",
	ExportExcel = "formulation.export_excel",
	DetailedResults = "formulation.detailed_results",
	CompactMode = "formulation.compact_mode",
	ToolShared = "formulation.tool_shared",
	TutorialNext = "tutorial.next",
	FaqSearch = "faq.search",
	FaqItemClicked = "faq.item.click",
}

export async function logActivity(action: AnalyticsAction, meta?: Record<string, unknown>) {
	const mutate = await apolloClient.mutate({
		mutation: graphql(`
			mutation LogActivity($input: ActivityCreateInput!) {
				logActivity(input: $input)
			}
		`),
		variables: {
			input: {
				action,
				meta,
			},
		},
	});

	return !!mutate.data;
}
