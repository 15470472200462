import type {
	AntimicrobialProduct,
	CnmimPortfolioProduct,
	CorbionProduct,
	PrototypeProduct,
	PureProduct,
	UnitOfWeight,
} from "@/graphql/graphql";
import { REFERENCE_FORMULATION_ID } from "@/store/formulations";
import type { Formulation, YourReferenceFormulation } from "@/types/calculation";

export * from "./select-options";

export const delay = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration));

export const round = (number: number, decimals = 2) =>
	Math.round((number + Number.EPSILON) * 10 ** decimals) / 10 ** decimals;

export const isPureProduct = (product: CnmimPortfolioProduct): product is PureProduct =>
	(product as PureProduct).__typename === "PureProduct";

export const isCorbionProduct = (product: CnmimPortfolioProduct): product is CorbionProduct =>
	(product as CorbionProduct).__typename === "CorbionProduct";

export const isAntimicrobialProduct = (
	product: CnmimPortfolioProduct,
): product is AntimicrobialProduct =>
	(product as AntimicrobialProduct).__typename === "AntimicrobialProduct";

export const isPrototypeProduct = (product: CnmimPortfolioProduct): product is PrototypeProduct =>
	(product as PrototypeProduct).__typename === "PrototypeProduct";

export const isYourReferenceFormulation = (
	formulation: Formulation,
): formulation is YourReferenceFormulation => formulation.id === REFERENCE_FORMULATION_ID;

export const convertWeight = ({
	from,
	to,
	amount,
}: {
	from: UnitOfWeight;
	to: UnitOfWeight;
	amount: number;
}): number => {
	let convertedToGrams = 0;
	let convertedWeight = amount;

	switch (from) {
		case "GRAMS":
		default: {
			convertedToGrams = amount;
			break;
		}
		case "KILO_GRAMS": {
			convertedToGrams = amount * 1000;
			break;
		}

		case "POUNDS": {
			convertedToGrams = amount * 453.59237;
			break;
		}

		case "OUNCES": {
			convertedToGrams = amount * 28.3495231;
			break;
		}
	}

	switch (to) {
		case "GRAMS":
		default: {
			convertedWeight = convertedToGrams;
			break;
		}
		case "KILO_GRAMS": {
			convertedWeight = convertedToGrams / 1000;
			break;
		}

		case "POUNDS": {
			convertedWeight = convertedToGrams / 453.59237;
			break;
		}

		case "OUNCES": {
			convertedWeight = convertedToGrams / 28.3495231;
			break;
		}
	}

	// Round to 2 decimals
	convertedWeight = round(convertedWeight);

	return convertedWeight;
};

export function countDecimals(number: number) {
	if (Math.floor(number.valueOf()) === number.valueOf()) {
		return 0;
	}

	const str = number.toString();
	if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
		return Number(str.split("-")[1]) || 0;
	} else if (str.indexOf(".") !== -1) {
		return str.split(".")[1].length || 0;
	}
	return Number(str.split("-")[1]) || 0;
}
