import type Shepherd from "shepherd.js";
import type { z } from "zod";
import { AnalyticsAction, logActivity } from "@/lib/logActivity";
import type { ToursSchema } from "@/store/tours";

type Meta = {
	tour: keyof z.infer<typeof ToursSchema>;
	step: string;
} & Record<string, any>;

export function handleTourNext(tour: Shepherd.Tour, meta: Meta) {
	logActivity(AnalyticsAction.TutorialNext, meta);
	return tour.next();
}

export function handleTourComplete(tour: Shepherd.Tour, meta: Meta) {
	logActivity(AnalyticsAction.TutorialNext, { ...meta, completed: true });
	return tour.complete();
}
