<template>
	<div class="sb_progress-bar">
		<transition name="fade">
			<div v-if="percentage <= 94" class="sb_progress-bar_percentage">{{ percentage }}%</div>
		</transition>

		<div class="sb_progress-bar_progress"></div>
	</div>
</template>

<script setup lang="ts">
import { computed, toRefs } from "vue";
import { round } from "@/utils";

const props = defineProps<{ value: number; max: number }>();

const { value, max } = toRefs(props);

const percentage = computed(() => {
	return round((value.value / max.value) * 100, 0);
});
</script>

<style lang="scss">
.sb_progress-bar {
	--width-percentage: v-bind(percentage);

	position: relative;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	padding: $padding;
	width: 100%;
	height: 40px;
	background: rgba($brand-primary, 0.1);
	border-radius: $border-radius;
	overflow: hidden;

	&_progress {
		position: absolute;
		inset: 0;
		min-width: 0;
		width: 100%;
		max-width: 100%;
		transform-origin: right center;
		transform: translateX(calc(calc(var(--width-percentage) - 100) * 1%));
		height: 100%;
		background: $brand-primary-lighter;
		will-change: transform;
		transition-property: transform;
		transition-duration: 250ms;
		transition-timing-function: linear;
	}

	&_percentage {
		position: absolute;
		inset: auto $padding * 0.5 auto auto;
		z-index: 1;
		color: $brand-primary;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition-property: opacity;
		transition-timing-function: $transition-timing-function;
		transition-duration: $transition-duration-short;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
}
</style>
