export const tooltipOptions = {
	themes: {
		"tooltip-bounds": {
			$extend: "tooltip",
			delay: {
				show: 0,
				hide: 0,
			},
			hideTriggers: ["hover"],
			modifiers: [
				{
					name: "preventOverflow",
					options: {
						mainAxis: true,
						altAxis: true,
						padding: 8,
					},
				},
			],
		},
		input: {
			$extend: "dropdown",
			offset: [0, 8],
			placement: "left",
			triggers: [],
			autoHide: false,
			delay: { show: 0, hide: 0 },
			modifiers: [
				{
					name: "preventOverflow",
					options: {
						mainAxis: true,
						altAxis: true,
						padding: 8,
					},
				},
				{
					name: "flip",
					options: {
						fallbackPlacements: ["top"],
					},
				},
			],
		},
		"input-inline-start": {
			$extend: "input",
			placement: "left",
		},
		"input-inline-end": {
			$extend: "input",
			placement: "right",
		},
		"input-error": {
			$extend: "input",
		},
		"input-error-inline-start": {
			$extend: "input-error",
			placement: "left",
		},
		"input-error-inline-end": {
			$extend: "input-error",
			placement: "right",
		},
		"icon-dropdown": {
			$extend: "dropdown",
		},
		"tab-dropdown": {
			$extend: "dropdown",
			placement: "bottom",
			triggers: [],
			delay: { show: 0, hide: 0 },
			offset: [0, 0],
			autoHide: true,
			modifiers: [
				{
					name: "preventOverflow",
					options: {
						mainAxis: true,
						altAxis: true,
						padding: 0,
					},
				},
				{
					name: "flip",
					options: {
						fallbackPlacements: ["top"],
					},
				},
			],
		},
	},
};
