import { CompanyEmployeeCount, CompanyType, JobPosition } from "@/types/enums";

export const companyTypes = [
	{ label: "Supplier", value: CompanyType.SUPPLIER },
	{ label: "Manufacturer", value: CompanyType.MANUFACTURER },
];

export const jobPositions = [
	{
		label: "Manufacturing or production",
		value: JobPosition.MANUFACTURING_OR_PRODUCTION,
	},
	{ label: "Marketing and sales", value: JobPosition.MARKETING_AND_SALES },
	{ label: "NPF or development", value: JobPosition.NPF_OR_DEVELOPMENT },
	{ label: "Purchasing", value: JobPosition.PURCHASING },
	{ label: "Quality assurance", value: JobPosition.QUALITY_ASSURANCE },
	{ label: "Quality control", value: JobPosition.QUALITY_CONTROL },
	{
		label: "Research and development",
		value: JobPosition.RESEARCH_AND_DEVELOPMENT,
	},
	{ label: "Other", value: JobPosition.OTHER },
];

export const companyEmployeeCounts = [
	{ label: "Prefer not to say", value: CompanyEmployeeCount.NOT_SET },
	{ label: "From 0 to 10", value: CompanyEmployeeCount.FROM_0_TO_10 },
	{ label: "From 10 to 100", value: CompanyEmployeeCount.FROM_10_TO_100 },
	{ label: "From 100 to 250", value: CompanyEmployeeCount.FROM_100_TO_250 },
	{ label: "From 250 to 1000", value: CompanyEmployeeCount.FROM_250_TO_1000 },
	{ label: "More than 1000", value: CompanyEmployeeCount.MORE_THAN_1000 },
];
