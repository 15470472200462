import { ref } from "vue";
import { defineStore } from "pinia";

export interface ConfirmationDialog {
	title?: string;
	message: string;
	cancelText?: string;
	confirmText?: string;
	onDismiss: () => void;
	onCancel: () => void;
	onOk: () => void;
}

export const useDialogStore = defineStore(
	"dialog",
	() => {
		const shown = ref<boolean>(false);
		const state = ref<ConfirmationDialog | Record<string, never>>({});

		async function confirmDialog(
			options: Omit<ConfirmationDialog, "onOk" | "onCancel" | "onDismiss">,
		) {
			return new Promise((resolve) => {
				const onOk = () => {
					resolve(true);

					onDismiss();
				};

				const onCancel = () => {
					resolve(false);

					onDismiss();
				};

				const onDismiss = () => {
					resolve(false);

					shown.value = false;
					state.value = {};
				};

				state.value = {
					...options,
					onOk,
					onCancel,
					onDismiss,
				};

				shown.value = true;
			});
		}

		return { shown, state, confirmDialog };
	},
	{
		persist: false,
	},
);
