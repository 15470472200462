<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
	SwitchRoot,
	type SwitchRootEmits,
	type SwitchRootProps,
	SwitchThumb,
	useForwardPropsEmits,
} from "radix-vue";
import { cx } from "@/lib/style/cva.config";

const props = withDefaults(
	defineProps<SwitchRootProps & { class?: HTMLAttributes["class"]; withStyles?: boolean }>(),
	{
		withStyles: true,
		class: undefined,
	},
);

const emits = defineEmits<SwitchRootEmits>();

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<SwitchRoot
		v-bind="forwarded"
		:class="
			cx(
				'peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-neutral-900 data-[state=unchecked]:bg-neutral-200 dark:focus-visible:ring-neutral-300 dark:focus-visible:ring-offset-neutral-950 dark:data-[state=checked]:bg-neutral-50 dark:data-[state=unchecked]:bg-neutral-800',
				withStyles &&
					'data-[state=checked]:bg-brand-primary-lighter data-[state=unchecked]:bg-neutral-400 dark:data-[state=checked]:bg-brand-primary-darker dark:data-[state=unchecked]:bg-neutral-600',
				props.class,
			)
		"
	>
		<SwitchThumb
			:class="
				cx(
					'pointer-events-none block size-5 rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-[1.375rem] data-[state=unchecked]:translate-x-0.5 dark:bg-neutral-950',
				)
			"
		/>
	</SwitchRoot>
</template>
