<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { ChevronUp } from "lucide-vue-next";
import { SelectScrollUpButton, type SelectScrollUpButtonProps, useForwardProps } from "radix-vue";
import { cx } from "@/lib/style/cva.config";

const props = defineProps<SelectScrollUpButtonProps & { class?: HTMLAttributes["class"] }>();

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
	<SelectScrollUpButton
		v-bind="forwardedProps"
		:class="cx('flex cursor-default items-center justify-center py-1', props.class)"
	>
		<slot>
			<ChevronUp class="size-4" />
		</slot>
	</SelectScrollUpButton>
</template>
