import { computed } from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { useIsAuthenticated } from "@stijlbreuk/msal-vue";
import { useWindowFocus } from "@vueuse/core";
import { defineStore } from "pinia";
import { graphql } from "@/graphql";

export const usePrivacyStatementStore = defineStore(
	"privacy-statement",
	() => {
		const isWindowFocused = useWindowFocus();
		const isAuthenticated = useIsAuthenticated();

		const hasAcceptedQuery = useQuery(
			graphql(`
				query PrivacyStatementHasAccepted {
					hasAcceptedPrivacyStatement
				}
			`),
			null,
			{
				fetchPolicy: "cache-and-network",
				notifyOnNetworkStatusChange: true,
				enabled: isAuthenticated.value && isWindowFocused.value,
			},
		);
		const hasAccepted = computed(
			() => hasAcceptedQuery.result.value?.hasAcceptedPrivacyStatement ?? true,
		);

		const acceptMutation = useMutation(
			graphql(`
				mutation AcceptPrivacyStatement {
					acceptPrivacyStatement
				}
			`),
			{ refetchQueries: "active" },
		);

		return { hasAccepted, accept: acceptMutation.mutate };
	},
	{
		persist: false,
	},
);
