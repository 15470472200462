import { computed, ref, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { useIsAuthenticated } from "@stijlbreuk/msal-vue";
import { useWindowFocus } from "@vueuse/core";
import { addDays, isPast, minutesToMilliseconds } from "date-fns";
import { defineStore, storeToRefs } from "pinia";
import { toast } from "vue-sonner";
import { graphql } from "@/graphql";
import { msalInstance } from "@/lib/services/msal/instance";
import { msalRequest } from "@/lib/services/msal/request";
import { useAuthStore } from "@/store/auth";
import type { PermissionValue } from "@/types/permissions";
import { getPermissions } from "@/utils/permissions";

export const usePermissionsStore = defineStore(
	"permissions",
	() => {
		const isWindowFocused = useWindowFocus();
		const isAuthenticated = useIsAuthenticated();
		const { token } = storeToRefs(useAuthStore());
		const accessToken = computed(() =>
			[token.value?.tokenType, token.value?.accessToken].join(" "),
		);

		const alertDismissed = ref<Date>();

		const permissions = computed<PermissionValue[]>(() => {
			if (!accessToken.value) {
				return [];
			}

			const token = accessToken.value.split(" ")[1];

			return getPermissions(token);
		});
		const loaded = computed(() => permissions.value.length > 0 || !!accessToken.value);

		const haveChangedQuery = useQuery(
			graphql(`
				query PermissionsHaveChanged {
					myPermissionsHaveChanged
				}
			`),
			null,
			{
				fetchPolicy: "cache-and-network",
				notifyOnNetworkStatusChange: true,
				enabled: isAuthenticated.value && isWindowFocused.value,
			},
		);
		const haveChanged = computed(
			() => haveChangedQuery.result.value?.myPermissionsHaveChanged ?? false,
		);

		watch(
			haveChanged,
			(value) => {
				if (!value || (alertDismissed.value && !isPast(alertDismissed.value))) {
					return;
				}

				toast.info("Your permissions have changed!", {
					id: "permissions-have-changed",
					description: "Re-authentication is required",
					onDismiss: () => (alertDismissed.value = addDays(new Date(), 1)),
					action: {
						label: "Update",
						onClick: async () =>
							await msalInstance.logoutRedirect({
								...msalRequest,
								postLogoutRedirectUri: "/redirect/sign-in",
							}),
					},
					duration: minutesToMilliseconds(30),
					important: true,
				});
			},
			{ immediate: true },
		);

		return { loaded, haveChanged, permissions };
	},
	{
		persist: {
			paths: ["alertDismissed"],
		},
	},
);
