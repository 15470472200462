import { ApolloClient } from "@apollo/client/core";
import { from } from "@apollo/client/core";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { apolloCache as cache } from "@/lib/services/apollo/cache";
import { createAuthLink } from "@/lib/services/apollo/links/authLink";
import { errorLink } from "@/lib/services/apollo/links/errorLink";
import { httpLink } from "@/lib/services/apollo/links/httpLink";
import { retryLink } from "@/lib/services/apollo/links/retryLink";
import { msalInstance } from "@/lib/services/msal/instance";

export const apolloClient = new ApolloClient({
	cache,
	link: from([
		removeTypenameFromVariables(),
		createAuthLink({ instance: msalInstance, inProgress: "none" }),
		retryLink,
		errorLink,
		httpLink,
	]),
});
