import {
	InteractionType,
	type PopupRequest,
	type PublicClientApplication,
	type RedirectRequest,
} from "@azure/msal-browser";
import type { Router } from "vue-router";
import { msalInstance } from "@/lib/services/msal/instance";
import { msalRequest } from "@/lib/services/msal/request";

export function registerGuard(router: Router) {
	router.beforeEach(async (to) => {
		if (to.meta.requiresAuth || to.meta.requiresUnauth) {
			const accounts = msalInstance.getAllAccounts();
			const isLoggedIn = accounts.length > 0;

			if (to.meta.requiresUnauth && isLoggedIn) {
				return { name: "Configure" };
			}
			if (to.meta.requiresAuth && !isLoggedIn) {
				const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, {
					...msalRequest,
					redirectStartPage: to.fullPath,
				});
				return shouldProceed || { name: "Authentication" };
			}
		}

		return true;
	});
}

export async function isAuthenticated(
	instance: PublicClientApplication,
	interactionType: InteractionType,
	loginRequest: PopupRequest | RedirectRequest,
): Promise<boolean> {
	// If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
	return instance
		.handleRedirectPromise()
		.then(() => {
			const accounts = instance.getAllAccounts();
			if (accounts.length > 0) {
				return true;
			}

			// User is not signed in and attempting to access protected route. Sign them in.
			switch (interactionType) {
				case InteractionType.Popup:
					return instance
						.loginPopup(loginRequest)
						.then(() => {
							return true;
						})
						.catch(() => {
							return false;
						});
				case InteractionType.Redirect:
					return instance
						.loginRedirect(loginRequest)
						.then(() => {
							return true;
						})
						.catch(() => {
							return false;
						});
				default:
					return false;
			}
		})
		.catch(() => {
			return false;
		});
}
