<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { ChevronDown } from "lucide-vue-next";
import { SelectIcon, SelectTrigger, type SelectTriggerProps, useForwardProps } from "radix-vue";
import { cx } from "@/lib/style/cva.config";

const props = withDefaults(
	defineProps<SelectTriggerProps & { class?: HTMLAttributes["class"]; withStyles?: boolean }>(),
	{
		class: undefined,
		withStyles: true,
	},
);

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
	<SelectTrigger
		v-bind="forwardedProps"
		:class="
			cx(
				'flex h-10 w-full items-center justify-between rounded-md border border-neutral-200 bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-neutral-950 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:bg-neutral-950 dark:ring-offset-neutral-950 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-300 [&>span]:line-clamp-1',
				withStyles &&
					'rounded-t-none border-b-2 border-l-2 border-r-2 border-t-0 border-solid border-brand-primary-lighter',
				props.class,
			)
		"
	>
		<slot />
		<SelectIcon as-child>
			<ChevronDown class="size-4 opacity-50" />
		</SelectIcon>
	</SelectTrigger>
</template>
