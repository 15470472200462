import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { z } from "zod";
import type { MoldGroup } from "@/graphql/graphql";
import { type FoodType, FoodTypeSchema } from "@/lib/constants/FoodType";

export const ConfigurationSchema = z.object({
	region: z.string().optional(),
	foodType: FoodTypeSchema.default("bread-buns"),
	productName: z.string().optional(),
	additionalInformation: z.string().optional(),
});

type CalculationDefault = {
	showFormulationParameters: boolean;
	moisture: CalculationDefaultInput;
	pH: CalculationDefaultInput;
	aW: CalculationDefaultInput;
	moldGroupsPriority: MoldGroup;
};
type CalculationDefaultInput = {
	min: number;
	step: number;
	max: number;
	default: number;
};

const foodTypeDefaults: Record<FoodType, CalculationDefault> = {
	"bread-buns": {
		showFormulationParameters: true,
		moisture: {
			min: 15,
			step: 0.1,
			max: 60,
			default: 37,
		},
		pH: {
			min: 4.5,
			step: 0.1,
			max: 6.5,
			default: 5.2,
		},
		aW: {
			min: 0.9,
			step: 0.001,
			max: 0.98,
			default: 0.94,
		},
		moldGroupsPriority: "PRESERVATIVE",
	},
	"sweet-goods": {
		showFormulationParameters: false,
		moisture: {
			min: 15,
			step: 0.1,
			max: 60,
			default: 27,
		},
		pH: {
			min: 5.5,
			step: 0.1,
			max: 8.5,
			default: 7,
		},
		aW: {
			min: 0.6,
			step: 0.001,
			max: 1,
			default: 0.75,
		},
		moldGroupsPriority: "COMMON_FOOD",
	},
	tortilla: {
		showFormulationParameters: true,
		moisture: {
			min: 15,
			step: 0.1,
			max: 60,
			default: 31,
		},
		pH: {
			min: 4.5,
			step: 0.1,
			max: 6.5,
			default: 5.2,
		},
		aW: {
			min: 0.9,
			step: 0.001,
			max: 0.98,
			default: 0.92,
		},
		moldGroupsPriority: "PRESERVATIVE",
	},
};

export const useConfigurationStore = defineStore(
	"configuration",
	() => {
		const region = ref(ConfigurationSchema.shape.region.parse(undefined));
		const foodType = ref(ConfigurationSchema.shape.foodType.parse(undefined));
		const productName = ref(ConfigurationSchema.shape.productName.parse(undefined));
		const additionalInformation = ref(
			ConfigurationSchema.shape.additionalInformation.parse(undefined),
		);

		const foodTypeDefault = computed<CalculationDefault>(() => foodTypeDefaults[foodType.value]);

		return {
			region,
			foodType,
			productName,
			additionalInformation,
			foodTypeDefault,
		};
	},
	{
		persist: {
			paths: ["region", "foodType", "productName", "additionalInformation"],
			serializer: {
				deserialize: (value) => ConfigurationSchema.parse(JSON.parse(value)),
				serialize: (value) => JSON.stringify(value),
			},
		},
	},
);
