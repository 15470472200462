import { createApp } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
// @ts-expect-error vue-katex is not typed
import VueKatex from "@hsorby/vue3-katex";
import { default as FloatingVue } from "floating-vue";
import "katex/dist/katex.min.css";
import { createPinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate";
import App from "@/App.vue";
import { registerComponents } from "@/components";
import { STORAGE_PREFIX, STORAGE_SEPARATOR } from "@/lib/constants/app";
import { msalVuePlugin } from "@/lib/packages/@stijlbreuk/msal-vue";
import "@/lib/polyfills/cryptoRandomUUID";
import { apolloClient } from "@/lib/services/apollo/client";
import { msalInstance } from "@/lib/services/msal/instance";
import { tooltipOptions } from "@/options";
import { router } from "@/router";
import { setupSentry } from "@/sentry";
import "@/styles/app.scss";
import { logInfo } from "@/utils/logInfo";

const pinia = createPinia();
pinia.use(
	createPersistedState({
		key: (id) => `${STORAGE_PREFIX}${STORAGE_SEPARATOR}${id}`,
		storage: localStorage,
	}),
);

const app = createApp(App);

app.provide(DefaultApolloClient, apolloClient);

// vue-router
app.use(router);

// floating-vue
app.use(FloatingVue, tooltipOptions);

// Components
registerComponents(app);
app.use(VueKatex, {});

// MSAL
app.use(msalVuePlugin, msalInstance);

// Pinia
app.use(pinia);

setupSentry({ app, router });

router.isReady().then(() => {
	// Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
	app.mount("#app");
});

logInfo();
