<template>
	<template v-if="showTemplate">
		<slot></slot>
	</template>
	<template v-else></template>
</template>

<script setup lang="ts">
// Based on https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/src/components/UnauthenticatedTemplate.tsx

/** Renders child components if user is unauthenticated */
import { computed, toRefs, watch } from "vue";
import { InteractionStatus } from "@azure/msal-browser";
import { useRouter } from "vue-router";
import { useIsAuthenticated } from "../hooks/useIsAuthenticated";
import { useMsal } from "../hooks/useMsal";

type AccountIdentifiers = {
	homeAccountId?: string | undefined;
	localAccountId?: string | undefined;
	username?: string | undefined;
};

const router = useRouter();

const props = withDefaults(defineProps<AccountIdentifiers>(), {
	username: undefined,
	homeAccountId: undefined,
	localAccountId: undefined,
});

const { username, homeAccountId, localAccountId } = toRefs(props);
const accountIdentifier = computed<AccountIdentifiers>(() => ({
	username: username.value,
	homeAccountId: homeAccountId.value,
	localAccountId: localAccountId.value,
}));
const isAuthenticated = useIsAuthenticated(accountIdentifier.value);
const { inProgress } = useMsal();

const showTemplate = computed(() => {
	return (
		!isAuthenticated.value &&
		inProgress.value !== InteractionStatus.Startup &&
		inProgress.value !== InteractionStatus.HandleRedirect
	);
});

watch(isAuthenticated, (isAuthenticated) => {
	if (isAuthenticated) {
		router.replace({ name: "Configure" });
	}
});
</script>
