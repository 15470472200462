<template>
	<teleport to="body">
		<transition name="modal-fade" appear>
			<div
				v-if="show"
				class="sb_modal"
				role="dialog"
				v-bind="$attrs"
				:class="{
					'sb_modal--results': modalType === 'results',
					'sb_modal--center': position === 'center',
					'sb_modal--top-left': position === 'top-left',
					'sb_modal--top': position === 'top',
					'sb_modal--top-right': position === 'top-right',
					'sb_modal--right': position === 'right',
					'sb_modal--bottom-right': position === 'bottom-right',
					'sb_modal--bottom': position === 'bottom',
					'sb_modal--bottom-left': position === 'bottom-left',
					'sb_modal--left': position === 'left',
				}"
			>
				<div class="sb_modal_inner">
					<div class="sb_modal_backdrop" @click="close"></div>
					<div class="sb_modal_content">
						<div class="sb_modal_content_top">
							<sb-button
								aria-label="Close Modal"
								button-style="icon"
								class="sb_modal_close"
								@click="close"
							>
								<sb-icon name="remove" />
							</sb-button>

							<sb-text v-if="title" type="h2">{{ title }}</sb-text>
						</div>

						<slot></slot>
					</div>
				</div>
			</div>
		</transition>
	</teleport>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, toRefs, watch } from "vue";
import SbButton from "@/components/atoms/Button.vue";
import SbIcon from "@/components/atoms/Icon.vue";
import SbText from "@/components/atoms/Text.vue";
import { useDialogStore } from "@/store/dialog";

const props = withDefaults(
	defineProps<{
		show: boolean;
		title?: string;
		askToClose?: boolean;
		modalType?: string;
		position?:
			| "center"
			| "top-left"
			| "top"
			| "top-right"
			| "right"
			| "bottom-right"
			| "bottom"
			| "bottom-left"
			| "left";
	}>(),
	{
		title: undefined,
		askToClose: false,
		modalType: undefined,
		position: "center",
	},
);

const { show, askToClose } = toRefs(props);

const { confirmDialog } = useDialogStore();

watch(show, (value) => {
	document.documentElement.style.overflow = value ? "hidden" : "unset";
});

const handleKeyDown = async (event: KeyboardEvent) => {
	if (event.key === "Escape") {
		if (!askToClose.value) {
			close();
		}

		if (
			askToClose.value &&
			(await confirmDialog({
				message: `Are you sure you wan't to close the modal and lose any information inserted?`,
				confirmText: "Close",
			}))
		) {
			close();
		}
	}
};

onMounted(() => {
	window.addEventListener("keydown", handleKeyDown);
});

onUnmounted(() => {
	window.removeEventListener("keydown", handleKeyDown);
});

const emit = defineEmits(["close"]);

const close = () => {
	emit("close");
};
</script>

<style lang="scss">
.modal-fade-enter-active,
.modal-fade-leave-active {
	transition-property: opacity;
	transition-timing-function: $transition-timing-function;
	transition-duration: $transition-duration-short;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
	opacity: 0;
}

.sb_modal {
	--padding: #{$padding};

	position: fixed;
	inset: 0;
	isolation: isolate;
	display: grid;
	z-index: 20000;
	padding: $padding;

	&--center {
		place-items: center center;
	}

	&--top-left {
		place-items: flex-start flex-start;
	}

	&--top {
		place-items: flex-start center;
	}

	&--top-right {
		place-items: flex-start flex-end;
	}

	&--right {
		place-items: center flex-end;
	}

	&--bottom-right {
		place-items: flex-end flex-end;
	}

	&--bottom {
		place-items: flex-end center;
	}

	&--bottom-left {
		place-items: flex-end flex-start;
	}

	&--left {
		place-items: center flex-start;
	}

	&_backdrop {
		position: fixed;
		inset: 0;
		background: rgba($brand-primary, 0.75);
		backdrop-filter: blur(3px);
		z-index: -1;
	}

	&_content {
		padding: $padding;
		border-radius: $border-radius;
		background: $brand-white;
		display: flex;
		flex-direction: column;
		gap: $padding;
		max-width: min(768px, calc(100vw - calc(#{$padding} * 2)));
		margin-inline: auto;
		max-height: calc(100vh - calc(#{$padding} * 2));
		overflow-y: auto;
		width: 100vw;

		@include media(">=md") {
			min-width: 60ch;
			width: 75vw;
			padding: $padding * 2 $padding * 3;
		}

		&_top {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
			gap: $padding;
			flex-wrap: wrap;
			align-items: center;
		}
	}

	&_close {
		--ring-color: #{$brand-primary};
		--color: #{$brand-primary};
		--button-icon-size: 20px;

		.sb_icon {
			will-change: transform;
			transition-property: transform;
			transition-timing-function: $transition-timing-function;
			transition-duration: $transition-duration-short;
		}

		&:hover {
			.sb_icon {
				transform: rotate(180deg);
			}
		}
	}

	&--results {
		.sb_modal_content {
			max-width: calc(100vw - calc(#{$padding} * 2));
		}
	}
}
</style>
