import { RetryLink } from "@apollo/client/link/retry";

// Retry on network
export const retryLink = new RetryLink({
	delay: {
		initial: 500,
		max: Infinity,
		jitter: true,
	},
	attempts: {
		max: 3,
		retryIf: (error) => !!error,
	},
});
