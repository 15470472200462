<script lang="ts" setup>
import type { HTMLAttributes } from "vue";
import type { LabelProps } from "radix-vue";
import { Label } from "@/components/ui/label";
import { cx } from "@/lib/style/cva.config";
import { useFormField } from "./useFormField";

const props = defineProps<LabelProps & { class?: HTMLAttributes["class"] }>();

const { error, formItemId } = useFormField();
</script>

<template>
	<Label :class="cx(error && 'text-red-500 dark:text-red-900', props.class)" :for="formItemId">
		<slot />
	</Label>
</template>
