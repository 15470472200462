import { setContext } from "@apollo/client/link/context";
import type { IPublicClientApplication, InteractionStatus } from "@azure/msal-browser";
import { getToken } from "@/lib/services/msal/getToken";

export function createAuthLink({
	instance,
	inProgress,
}: {
	instance: IPublicClientApplication;
	inProgress: InteractionStatus;
}) {
	return setContext(async (_, { headers }) => {
		const token = await getToken(instance, inProgress);

		if (!token) {
			return;
		}

		return {
			headers: {
				...headers,
				Authorization: [token.tokenType, token.accessToken].join(" "),
			},
		};
	});
}
