<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { useVModel } from "@vueuse/core";
import { cx } from "@/lib/style/cva.config";

const props = withDefaults(
	defineProps<{
		defaultValue?: string | number;
		modelValue?: string | number;
		class?: HTMLAttributes["class"];
		withStyles?: boolean;
	}>(),
	{
		defaultValue: undefined,
		modelValue: undefined,
		class: undefined,
		withStyles: true,
	},
);

const emits = defineEmits<{
	(e: "update:modelValue", payload: string | number): void;
}>();

const modelValue = useVModel(props, "modelValue", emits, {
	passive: true,
	defaultValue: props.defaultValue,
});
</script>

<template>
	<input
		v-model="modelValue"
		:class="
			cx(
				'flex h-10 w-full rounded-md border border-neutral-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:bg-neutral-950 dark:ring-offset-neutral-950 dark:placeholder:text-neutral-400 dark:focus-visible:ring-neutral-300',
				withStyles &&
					'rounded-t-none border-b-2 border-l-2 border-r-2 border-t-0 border-solid border-brand-primary-lighter',
				props.class,
			)
		"
	/>
</template>
